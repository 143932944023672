/* eslint-disable */

//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DeleteQuoteItemByEstimator,
  DeleteQuoteTag,
  DeleteQuoteTool,
  GetCaseById,
  GetQuoteByIdForUserDisplay,
  GetQuoteItem,
  GetQuoteTag,
  QuoteStatus,
  ReverseQuant_QuoteItem,
  SetQuoteTo_Buy,
  SetQuoteTo_Buy_Install,
  SetQuoteTo_Notify,
  UpdateQuote,
} from "../../API_Functions/CaseQuote_Functions";
import {
  AddImageToCloud,
  decimalHoursToTimeString,
  GetCurrentTime,
  SetHeaderAuthorization,
} from "../../API_Functions/Utility_Functions";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import TopMentProposalView from "../../comps/MenuComps/TopMenuProposalView";
import ImageViewWindow from "../../comps/Windows/ImageEnlargementWindow";
import RevisionInstructionsWindow from "../../comps/Windows/RevisionInstructions";
import DuplicateProposalWindow from "../../comps/Windows/SaveRevisionGuide";
import MobileEstimateQuoteItem from "../../MobileComponents/MobileEstimateQuoteItem";
import { Stored_Values } from "../../Stored_Values";
import { CaseStatus } from "../../StylesheetComps/CaseStatus";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FixedIconToTop,
  RegularResponsiveButton,
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import { GetTaxesForMetro } from "../../API_Functions/Store_Functions";
import QuoteItemEstimator from "../../comps/QuoteItemEstimator";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import QuoteLineItem from "../../comps/Windows/QuoteLineItem";
import QuotePhotoUploadPreviewWindow from "../../comps/Windows/QuotePhotoUpload";
import TagWindow from "../../comps/Windows/Tag";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import {
  AutoSizeOverflowY,
  FlexColumn81vwLightGrey,
  FlexColumnCont100xAutoPercent,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexColumnFullWidthMargins,
  FlexContFullWidthHeightJustifyCenter,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont60PercentSpaceEvenly,
  FlexRowContAutox100PercentMobileColumn,
  FlexRowContFlexStart,
  FlexRowContFlexStartMobileColumn,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import {
  FlexColumnDiv,
  FlexContainerItem,
  FlexContainerPhotos,
  FlexDivMargins,
  GridContainer3Wide,
  SummaryContainer,
  SummaryContainerItems,
} from "../../StylesheetComps/Div";
import { Icon200x200 } from "../../StylesheetComps/Imgs/Icons";
import {
  OverrideEmbedDiv,
  OverrideEmbedImage,
} from "../../StylesheetComps/Imgs/Images";
import { InputField } from "../../StylesheetComps/Inputs";
import {
  Image375px,
  TextAreaAutoResize,
} from "../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../StylesheetComps/Menu";
import {
  Cont,
  ContentCont44Percent,
  ContentCont77vw,
  ContentContQuote,
} from "../../StylesheetComps/Projects";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LargeText,
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  MediumHeader,
  StyledHRGreenBreak,
  StyledHRGreenBreakThick,
  StyledHRPropColour,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";

const ViewProposalAccountantView = () => {
  // React-router variables
  const history = useHistory();
  const params = useParams();

  // Data variables
  const [Quote, setProposal] = useState({});
  const [Case, setCase] = useState({});
  const [curTime, setCurTime] = useState("");
  var MaterialSum = 0;
  var InstallSum = 0;
  var TotalSum = 0;
  var materialSub = 0;
  var installSub = 0;

  const flattenItems = (items, quoteTitle, caseName) => {
    let flattened = [];
    let processedIds = new Set(); // To keep track of processed items

    // Add custom headers
    flattened.push({ Name: `Quote: ${quoteTitle}`, Unit: "", Quant: "" });
    flattened.push({
      Name: `Date: ${new Date().toLocaleDateString()}`,
      Unit: "",
      Quant: "",
    });
    flattened.push({ Name: `Homeowner: ${caseName}`, Unit: "", Quant: "" });
    flattened.push({ Name: "", Unit: "", Quant: "" }); // Empty line for separation

    // Separate primary and related items
    const primaryItems = items?.filter(
      (item) => item.Tag_Id !== 0 && item.CateType !== CategoryTypes.Labor
    );
    const relatedItems = items?.filter(
      (item) => item.Tag_Id === 0 && item.CateType !== CategoryTypes.Labor
    );

    // Sort primary items by Tag_Id
    primaryItems?.sort((a, b) => a.Tag_Id - b.Tag_Id);

    primaryItems?.forEach((primary) => {
      // Add the primary item to the flattened array
      flattened.push({
        Name: primary.Name,
        Unit: primary.Unit,
        Quant: primary.Quant,
      });

      // Mark this primary item as processed
      processedIds.add(primary.Id);

      // Find and add related items for this primary item
      const relatedForThisPrimary = relatedItems?.filter(
        (item) => item.Parent_Id === primary.Id
      );
      relatedForThisPrimary?.forEach((related) => {
        if (!processedIds.has(related.Id)) {
          flattened.push({
            Name: related.Name,
            Unit: related.Unit,
            Quant: related.Quant,
          });
          processedIds.add(related.Id);
        }
      });

      // Add a space to separate this tag group
      flattened.push({ Name: "", Unit: "", Quant: "" });
    });

    return flattened;
  };

  const materialItems = flattenItems(
    Quote?.Material?.Items,
    Quote?.Tittle,
    Case?.Name
  );
  const laborItems = flattenItems(
    Quote?.Labor?.Items,
    Quote?.Tittle,
    Case?.Name
  );
  const removalItems = flattenItems(
    Quote?.Removal?.Items,
    Quote?.Tittle,
    Case?.Name
  );

  // Totals
  const [supplyTotal, setSupplyTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [installTotal, setInstallTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [projectSubtotal, setProjectSubtotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [projectGrandTotal, setProjectGrandTotal] = useState(0);
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [accessTotal, setAccessTotal] = useState(0);

  // Display control for headers
  const [toolsmachinesHeader, setToolsMachinesHeader] = useState("flex");
  const [removalHeader, setRemovalHeader] = useState("none");
  const [accessDisplay, setAccessDisplay] = useState("none");
  const [generalMaterialsHeader, setGeneralMaterialsHeader] = useState("flex");
  const [deliveryHeader, setDeliveryHeader] = useState("none");

  // Display control for various elements
  const [installEstimateDisplay, setInstallEstimateDisplay] = useState("flex");
  const [laborDetailView, setLaborDetailView] = useState("none");
  const [showGenerateInovice, setShowGenerateInvoice] = useState("none");
  const [laborDetailToggleView, setLaborDetailToggleView] = useState("none");
  const [laborDetailArrow, setLaborDetailArrow] = useState("/downarrow.svg");
  const [discardButtonDisplay, setDiscardButtonDisplay] = useState("none");
  const [makeRevisionsButtonDisplay, setMakeRevisionsButtonDisplay] =
    useState("block");
  const [saveRevisionsButtonDisplay, setSaveRevisionsButtonDisplay] =
    useState("none");
  const [cloneProposalButtonDisplay, setCloneProposalButtonDisplay] =
    useState("none");
  const [showDepositButton, setShowDepositButton] = useState("block");
  const [labourBreakdownDisplay, setShowLabourBreakdown] = useState("none");
  const [showRelated, setShowRelated] = useState(false);
  const [laborDetailToggle, setLaborDetailToggle] = useState(false);

  // UI controls for image preview
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageGalleryDisplay, setImageGalleryDisplay] = useState(false);
  const [cloneProposalWindowDisplay, setCloneProposalWindowDisplay] =
    useState(false);

  // Revision instruction window
  const [revisionInstructionDisplay, setRevisionInstructionDisplay] =
    useState(false);
  const [revisionAcceptDisplay, setRevisionAcceptDisplay] = useState("none");
  const [revisionDeclineDisplay, setRevisionDeclineDisplay] = useState("none");

  // Deletion state
  const [deleteState, setDeleteState] = useState(false);

  // Header input state
  const [headerInputShown, setHeaderInputShown] = useState(0);

  // Opacity control
  const [supplyTotalOpacity, setSupplyTotalOpacity] = useState("100%");
  const [installTotalOpacity, setInstallTotalOpacity] = useState("100%");

  // Naming
  const [projectName, setProjectName] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [homeownerName, setHomeownerName] = useState("");
  const [projectCaseNumber, setProjectCaseNumber] = useState(0);

  // File naming
  const [invoicefileMaterial, setInvoicefileMaterial] = useState(
    "invoice_material.pdf"
  );
  const [invoicefileInstall, setInvoicefileInstall] = useState(
    "invoice_install.pdf"
  );

  // Totals for untagged items
  const [untaggedMaterialSubtotal, setUntaggedMaterialSubtotal] = useState(0);
  const [untaggedInstallSubtotal, setUntaggedInstallSubtotal] = useState(0);
  const [untaggedTotalSubtotal, setUntaggedTotalSubtotal] = useState(0);

  // Error handling
  const [errorAgreementText, setErrorAgreementText] = useState("");

  // Supply control
  const [supplyOnlySelected, setSupplyOnlySelected] = useState(false);

  // Taxation
  const [storeTaxes, setStoreTaxes] = useState(false);

  // Scrolling control
  const scrollToOverview = document.getElementById("overview");
  const scrollToSupply = document.getElementById("supply");
  const scrollToInstall = document.getElementById("install");
  const scrollToDesigns = document.getElementById("designs");
  const scrollToComments = document.getElementById("comments");
  const scrollToConfirm = document.getElementById("confirmation");

  // Case status
  const [caseStatus, setCaseStatus] = useState(0);

  // Button reference
  const buttonRef = React.useRef(null);
  const [displayStatus, setDisplayStatus] = useState({});
  const [deliveryDisplayStatus, setDeliveryDisplayStatus] = useState(false);
  const [removalDisplayStatus, setRemovalDisplayStatus] = useState(false);

  let removalsHeader7Subtotal = 0;
  let [removalsHeader8Subtotal, setRemovalsHeader8Subtotal] = useState(0);
  let toolsHeader7Subtotal = 0;
  let toolsHeader8Subtotal = 0;
  let removalPriceSubtotal = 0;
  let removalTimeSubtotal = 0;
  const [projectInstallSubtotal, setProjectInstallSubtotal] = useState(0);

  //Edit Tag Variables
  const [tagObj, setTagObj] = useState(null);
  const [tagWindowDisplay, setTagWindowDisplay] = useState(false);

  // Delete Tag Variables

  const [deleteTagId, setDeleteTagId] = useState(0);
  const [deleteTagConfirmDisplay, setDeleteTagConfirmDisplay] = useState(false);

  //Add Blank Item
  const [newQuoteItem, setNewQuoteItem] = useState({});

  const GetBlankQuoteItem = async () => {
    const data = await GetQuoteItem(0);
    console.log(data);
  };

  const formattedDateTime = async () => {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString("default", { month: "short" });
    const year = now.getFullYear();
    let hour = now.getHours();
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minute = now.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }

  const downloadPDF = async () => {
    const timestamp = await formattedDateTime();
    const input = document.getElementById("estimate");

    const includeElement = document.getElementById("include-on-pdf");
    const originalDisplayStyle = includeElement.style.display;
    includeElement.style.display = "flex";

    html2canvas(input, {
      useCss: true,
      scale: 1, // Reduce the size of the canvas
      ignoreElements: (element) => {
        return element.id === "exclude-from-pdf";
      },
    }).then((canvas) => {
      includeElement.style.display = originalDisplayStyle;

      const imgData = canvas.toDataURL("image/jpeg", 1); // Use JPEG with 50% quality
      const pdf = new jsPDF("p", "mm", "a4"); // set the PDF to A4 size
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pageWidth;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      let pdfPages = Math.ceil(pdfHeight / pageHeight);

      for (let i = 0; i < pdfPages; i++) {
        if (i !== 0) {
          pdf.addPage();
        }
        pdf.addImage(imgData, "PNG", 0, -(i * pageHeight), pdfWidth, pdfHeight);
      }

      pdf.save(`${timestamp} ${Case?.Street}.pdf`);
    });
  };

  const DisplayImageInGallery = async (url) => {
    if (!url) return;

    setImagePreviewUrl(url);
    setImageGalleryDisplay(true);
    document.body.style.overflow = "hidden";
  };

  const DisplayLaborBreakdown = async (toggle) => {
    if (toggle) {
      setLaborDetailArrow("/downarrow.svg");
      setLaborDetailToggleView("none");
      setLaborDetailToggle(false);
    } else {
      setLaborDetailToggle(true);
      setLaborDetailArrow("/uparrow.svg");
      setLaborDetailToggleView("flex");
    }
  };

  const toggleDisplay = (id) => {
    setDisplayStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const toggleRemovalDisplay = (id) => {
    setRemovalDisplayStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const GetProjectInformation = async (caseId) => {
    const data = await GetCaseById(caseId);
    const { Case } = data || {};

    if (!Case) return;

    const { Name, Tittle, CaseNumber, Street, City, CaseStatus } = Case;

    setCase(Case);

    if (Name) setHomeownerName(Name);
    if (Tittle) setProjectName(Tittle);
    if (CaseNumber) setProjectCaseNumber(CaseNumber);

    if (Street && City) {
      const ext = `${Street}-${City}`;
      setInvoicefileInstall(`invoice_install_${ext}.pdf`);
      setInvoicefileMaterial(`invoice_material_${ext}.pdf`);
    }

    if (
      [
        CaseStatus.Closed,
        CaseStatus.Canceled,
        CaseStatus.CancelRequested,
        CaseStatus.Drafted,
      ].includes(CaseStatus)
    ) {
      setMakeRevisionsButtonDisplay("none");
    }

    setCaseStatus(CaseStatus);
  };

  const DisplayDepositButton = async () => {
    if (caseStatus === CaseStatus.Construction) {
      setShowDepositButton("none");
    } else setShowDepositButton("block");
  };

  const [estimateGST, setEstimateGST] = useState(0);
  const [estimatePST, setEstimatePST] = useState(0);

  const GetProposalInformation = async (quoteid) => {
    const data = await GetQuoteByIdForUserDisplay(quoteid);
    if (!data || !data.quote) return;

    const { quote } = data;

    let removalsHeader7Subtotal = 0;
    let removalsHeader8Subtotal = 0;

    quote?.Removal?.Items.forEach((o) => {
      removalsHeader7Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
      removalsHeader8Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
    });

    let projectInstallSubtotal = 0;

    quote?.Tags?.forEach((t) => {
      const primaryItems = quote?.Material?.Items.filter(
        (item) => item.Tag_Id === t.Id && item.Parent_Id === 0
      );

      primaryItems.forEach((o) => {
        const installSub =
          showRelated === true
            ? o.LaborSubtotal
            : o.LaborSubtotalWithRelatedItems;
        projectInstallSubtotal += installSub;
      });
    });

    quote?.Material?.Items.forEach((o) => {
      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
        const installSub =
          showRelated === true
            ? o.LaborSubtotal
            : o.LaborSubtotalWithRelatedItems;
        projectInstallSubtotal += installSub;
      }
    });

    setProjectInstallSubtotal(projectInstallSubtotal);
    setProposal(quote);
    setDeliveryTotal(
      quote?.Delivery?.Tools?.reduce(
        (accumulator, tool) => accumulator + tool.Price,
        0
      ) || 0
    );
    setAccessTotal(
      quote?.AccessArea?.Items?.reduce(
        (accumulator, items) => accumulator + items.Price,
        0
      ) || 0
    );
    UpdateUIBasedOnStatus(quote);

    const materialSubtotal = quote?.Material?.Total?.Subtotal || 0;
    const deliverySubtotal = quote?.Delivery?.Total?.Subtotal || 0;
    const toolSubtotal = quote?.Tool?.Total?.Subtotal || 0;
    const accessAreaSubtotal = quote?.AccessArea?.Total?.Subtotal || 0;
    const installSubtotal =
      Number(projectInstallSubtotal) > Stored_Values.MIN_LABOR
        ? Number(projectInstallSubtotal)
        : Stored_Values?.MIN_LABOR;

    supplyTotal.Subtotal = (materialSubtotal + deliverySubtotal).toFixed(2);
    installTotal.Subtotal = (
      installSubtotal +
      toolSubtotal +
      removalsHeader7Subtotal +
      accessAreaSubtotal
    ).toFixed(2);

    const subtotal =
      Number(supplyTotal.Subtotal) + Number(installTotal.Subtotal);
    setProjectSubtotal(
      subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );

    if (quote.Tittle) {
      setEstimateName(quote.Tittle);
    }

    const storeTaxArray = await GetTaxesForMetro(params.Id);
    setStoreTaxes(storeTaxArray);

    let gstTax = 0;
    // let pstTax = 0;

    const taxitems = [
      { type: "Material", subtotal: materialSubtotal },
      { type: "Delivery", subtotal: deliverySubtotal },
      { type: "Labor", subtotal: installSubtotal },
      { type: "Removal", subtotal: removalsHeader7Subtotal },
      { type: "AccessArea", subtotal: accessAreaSubtotal },
      { type: "Tool", subtotal: toolSubtotal },
    ];

    const gst = storeTaxArray.find((tax) => tax.TaxCode === "Gst");
    // const pst = storeTaxArray.find(tax => tax.TaxCode === 'PST');

    if (gst) {
      taxitems.forEach((item) => {
        const { type, subtotal } = item;

        if (type === "Material") {
          gstTax += (gst.Rate / 100) * subtotal;
          // pstTax += (pst.Rate / 100) * subtotal;
        } else {
          gstTax += (gst.Rate / 100) * subtotal;
        }
      });
    }

    gstTax = gstTax.toFixed(2);
    // pstTax = pstTax.toFixed(2);

    // setEstimatePST(pstTax);
    setEstimateGST(gstTax);

    computeTotalHours(data.quote.Material.Items, data.quote.Labor.Items);
    computeRemovalsTotal(data.quote.Removal.Items, data.quote.Labor.Items);
    const grandTotal = Number(gstTax) + Number(subtotal.toFixed(2));
    setProjectGrandTotal(grandTotal);
    setDeposit(grandTotal * 0.1);
  };

  const UpdateUIBasedOnStatus = async (quoteobj) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo.Role === UserRole.Manager) {
      if (quoteobj.QuoteStatus === QuoteStatus.RevisionRequested) {
        setRevisionDeclineDisplay("block");
        setRevisionAcceptDisplay("block");
      }
      setCloneProposalButtonDisplay("block");
    } else if (userInfo.Role === UserRole.Estimator) {
      setCloneProposalButtonDisplay("block");
    }
  };

  const ChangeUIToMakeRevisions = async () => {
    setHeaderInputShown(3);
    setMakeRevisionsButtonDisplay("none");
    setDiscardButtonDisplay("block");
    setSaveRevisionsButtonDisplay("block");
  };

  const UpdateQuoteItemQuantity = async (itemid, itemquantity) => {
    var reverseQuantity = await ReverseQuant_QuoteItem(itemid, itemquantity);
    Quote.Material.Items.find(function (o, i) {
      if (o.Id === itemid) {
        o.Quant = itemquantity;
        o.Price = reverseQuantity * o.UnitPrice;
        setSupplyTotalOpacity("40%");
        setInstallTotalOpacity("40%");
      }
      return null;
    });
    //Change Delete State to force a rerender in React
    setDeleteState(!deleteState);
  };

  const ValidateAgreement = async (supplyonly, caseid, quoteid) => {
    if (supplyonly === true) {
      var databuy = await SetQuoteTo_Buy(quoteid);
      if (databuy !== null) {
        PushToPayment(deposit.toFixed(2), caseid, quoteid);
      } else setErrorAgreementText("Please try again!");
    } else {
      var databuyinstall = await SetQuoteTo_Buy_Install(quoteid);
      if (databuyinstall !== null) {
        var depositSelect =
          deposit < Stored_Values.MIN_DEPOSIT
            ? Stored_Values.MIN_DEPOSIT
            : deposit;
        PushToPayment(depositSelect.toFixed(2), caseid, quoteid);
      } else setErrorAgreementText("Please try again!");
    }
  };

  const [totalHoursByProduct, setTotalHoursByProduct] = useState({});
  const [totalRemovalsByItem, setTotalRemovalsByItem] = useState({});
  const [laborNamesByProduct, setLaborNamesByProduct] = useState({});

  function computeTotalHours(productsArray, labourArray) {
    const totalHoursByProduct = {};
    const laborNamesByProduct = {};

    productsArray.forEach((product, productIndex) => {
      if (product.Parent_Id === 0) {
        let totalHours = 0;

        labourArray.forEach((labour) => {
          if (
            (labour.Notes === `Install ${product.Name}` ||
              labour.Notes === product.Name) &&
            labour.Parent_Id === product.Id
          ) {
            totalHours += labour.Quant;
            if (!laborNamesByProduct[`${product.Name}_${productIndex}`]) {
              laborNamesByProduct[`${product.Name}_${productIndex}`] =
                new Set();
            }
            laborNamesByProduct[`${product.Name}_${productIndex}`].add(
              labour.Name
            );
          }
        });

        productsArray.forEach((relatedProduct) => {
          if (relatedProduct.Parent_Id === product.Id) {
            labourArray.forEach((labour) => {
              if (
                (labour.Notes === `Install ${relatedProduct.Name}` ||
                  labour.Notes === relatedProduct.Name) &&
                labour.Parent_Id === relatedProduct.Id
              ) {
                totalHours += labour.Quant;
              }
            });
          }
        });

        totalHoursByProduct[`${product.Name}_${productIndex}`] = totalHours;
      }
    });

    setTotalHoursByProduct(totalHoursByProduct);
    setLaborNamesByProduct(laborNamesByProduct);
  }

  function computeRemovalsTotal(removalsArray, labourArray) {
    const totalRemovalsByItem = {};
    const assignedLabours = new Set();

    removalsArray.forEach((removal, removalIndex) => {
      let totalRemovals = 0;

      labourArray.forEach((labour) => {
        if (assignedLabours.has(labour.Id)) return; // Skip already assigned labours

        const removalMatch = removalsArray.reduce((closest, r) => {
          if (
            labour.Notes.includes("Removals") &&
            labour.Notes.split("-").some((part) => r.Name.includes(part.trim()))
          ) {
            const currentDiff = Math.abs(
              r.Price + r.LaborSubtotal - labour.Price
            );
            const closestDiff = closest
              ? Math.abs(closest.Price + closest.LaborSubtotal - labour.Price)
              : Infinity;

            return currentDiff < closestDiff ? r : closest;
          }
          return closest;
        }, null);

        if (
          removalMatch &&
          (removalMatch.Id === removal.Id ||
            removalMatch.Parent_Id === removal.Id)
        ) {
          totalRemovals += labour.Quant;
          assignedLabours.add(labour.Id); // Mark the labour as assigned
        }
      });

      totalRemovalsByItem[`${removal.Name}_${removalIndex}`] = totalRemovals;
    });

    setTotalRemovalsByItem(totalRemovalsByItem);
  }

  const PushToPayment = async (amount, caseid, quoteid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      if (data.Case !== null) {
        var token = axios.defaults.headers.common["Authorization"];
        if (token) {
          var tokenRef = token.substring(6);
        } else {
          tokenRef = "am9zaHRlc3QxMDU6QWExMjM0NTY3OCQ=";
        }
        var name =
          data.Case.Name +
          ", " +
          data.Case.Street +
          "," +
          data.Case.City +
          " on Project # " +
          data.Case.CaseNumber;
        var transactionid = data.Case.CaseNumber;
        var paymenturl = await MakeStripePay(
          amount,
          "CAD",
          name,
          transactionid,
          quoteid,
          data.Case.Store_Id,
          tokenRef,
          invoiceid
        );
        if (paymenturl !== null) {
          window.open(paymenturl, "_blank");
        }
      }
    }
  };

  const SetCurrentTime = async () => {
    var curtime = await GetCurrentTime();
    setCurTime(curtime);
  };

  const ref = React.createRef();
  const ref2 = React.createRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [12, 40],
  };

  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const SetQuoteDisplay = async (quote) => {
    if (!quote) return;

    const { Role } = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      Role === UserRole.Installer ||
      Role === UserRole.Manager ||
      Role === UserRole.Estimator
    ) {
      setLaborDetailView("block");
    }

    if (
      Role === UserRole.Manager ||
      Role === UserRole.Estimator ||
      Role === UserRole.Accountant
    ) {
      setShowGenerateInvoice("block");
      setShowLabourBreakdown("flex");
    }

    // if (quote.Material?.Items.some(item => item.Parent_Id === 0 && item.Tag_Id === 0))
    //   setGeneralMaterialsHeader("flex");

    if (quote.Delivery?.Tools?.length > 0) setDeliveryHeader("flex");

    if (quote.Tool?.Tools?.length > 0) setToolsMachinesHeader("flex");

    if (quote.Removal?.Items?.length > 0) setRemovalHeader("flex");

    if (quote.AccessArea?.Items?.length > 0) setAccessDisplay("flex");
  };

  const RouteToPrevious = async (role) => {
    console.log(role);
    if (role === UserRole.Manager || role === UserRole.Estimator) {
      history.push(
        "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      );
      // } else if (params.lastrevision == 0) {
      //   history.push(
      //     "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      //   );
    } else {
      history.push(
        "/viewcaseuser/" +
          params.Id +
          "/" +
          params.storeId +
          "/" +
          params.caseId
      );
    }
  };

  const DeleteTagOnQuote = async (tag_id, quote_id) => {
    // Invoke the DeleteQuoteTag function and wait for the promise to resolve
    const data = await DeleteQuoteTag(tag_id);

    // Check if the data variable is null
    if (data === null) {
      // If the data variable is null, invoke the GetQuoteInfoById function
      GetProposalInformation(quote_id);
    }
  };

  //Project Comment Files
  const [previewImage, setPreviewImage] = useState("");
  const [previewImageWindowDisplay, setPreviewImageWindowDisplay] =
    useState(false);
  const inputFile = useRef(null);
  const [photo, setPhoto] = useState("");

  const AddPhotoButtonClick = () => {
    inputFile.current.click();
  };

  const handleUpload = (event) => {
    setPhoto(event.target.files[0]);
  };

  let formdata = new FormData();

  const OpenUploadImageWindow = async (photo) => {
    // We want to use the try-catch pattern here to handle any errors that
    // may occur when making the API call to AddImageToCloud
    try {
      // Check if we have a photo
      if (photo !== null) {
        // Add the photo to the form data
        formdata.append("Quote Photo", photo);

        // Make the API call and store the result in a variable
        const uploadedImage = await AddImageToCloud(formdata);

        // If we have a result, set the preview image and open the window
        if (uploadedImage !== null) {
          setPreviewImage(uploadedImage);
          setPreviewImageWindowDisplay(true);
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const ResetImage = async (imgurl) => {
    DeleteImageFromCloud(imgurl);
    setPreviewImageWindowDisplay(false);
  };

  const DeleteImageFromCloud = async (imgUrl) => {
    try {
      await axios.delete(`${window.$DBURL}utilities/image`, {
        data: { Url: imgUrl },
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (photo !== "") {
      OpenUploadImageWindow(photo);
    }
  }, [photo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    SetHeaderAuthorization();
    GetProposalInformation(params.quoteId);
    GetProjectInformation(params.caseId);
    DisplayDepositButton();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let header7Subtotal = 0;
    let header8Subtotal = 0;

    Quote?.Removal?.Items.forEach((o) => {
      header7Subtotal += o.Price + o.LaborSubtotal;
      header8Subtotal += o.Price + o.LaborSubtotal;
    });

    setRemovalsHeader8Subtotal(header8Subtotal);
  }, [Quote?.Removal?.Items]);

  useEffect(() => {
    if (params.numberofquotes === 0) {
      setMakeRevisionsButtonDisplay("none");
      setHideProjectConfirmationDisplay("none");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newUntaggedMaterialSubtotal = 0;
    let newUntaggedInstallSubtotal = 0;
    let newUntaggedTotalSubtotal = 0;

    Quote?.Material?.Items.forEach((o) => {
      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
        const materialSub = o.SubtotalWithRelatedItems;
        const installSub = o.LaborSubtotalWithRelatedItems;

        newUntaggedMaterialSubtotal += materialSub;
        newUntaggedInstallSubtotal += installSub;
        newUntaggedTotalSubtotal += materialSub + installSub;
      }
    });

    setUntaggedMaterialSubtotal(newUntaggedMaterialSubtotal);
    setUntaggedInstallSubtotal(newUntaggedInstallSubtotal);
    setUntaggedTotalSubtotal(newUntaggedTotalSubtotal);
  }, [Quote?.Material?.Items]);

  useEffect(() => {
    SetCurrentTime();
    SetQuoteDisplay(Quote);
  }, [Quote]); // eslint-disable-line react-hooks/exhaustive-deps

  var observe;
  if (window.attachEvent) {
    observe = function (element, event, handler) {
      element?.attachEvent("on" + event, handler);
    };
  } else {
    observe = function (element, event, handler) {
      element?.addEventListener(event, handler, false);
    };
  }
  function init() {
    var text = document.getElementById("text");
    if (text !== null) {
      function resize() {
        text.style.height = "auto";
        text.style.height = text.scrollHeight + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }

  useEffect(() => {
    init();
  }, [Quote.Notes]); // eslint-disable-line react-hooks/exhaustive-deps

  // function convertHoursToTimeString(businessHours) {
  //   const HOURS_IN_DAY = 8; // Business hours in a day
  //   const DAYS_IN_WEEK = 5; // Business days in a week
  //   const HOURS_IN_WEEK = HOURS_IN_DAY * DAYS_IN_WEEK; // Business hours in a week

  //   if (businessHours < HOURS_IN_DAY) {
  //     return "1 day";
  //   } else if (businessHours < HOURS_IN_DAY * 4) {
  //     let days = Math.floor(businessHours / HOURS_IN_DAY);
  //     return `${days}-${days + 1} days`;
  //   } else if (businessHours <= HOURS_IN_WEEK) {
  //     return "1-2 weeks";
  //   } else {
  //     let weeks = Math.floor(businessHours / HOURS_IN_WEEK);
  //     let remainingHours = businessHours % HOURS_IN_WEEK;

  //     let lowerWeekBound = weeks;
  //     let upperWeekBound = weeks + 1;

  //     if (remainingHours > 0 && remainingHours <= HOURS_IN_DAY * 4) {
  //       upperWeekBound = weeks + 1; // Changed this line to ensure no more than a 1-week range
  //     }

  //     if (weeks < 5) {
  //       return `${lowerWeekBound}-${upperWeekBound} weeks`;
  //     } else {
  //       let lowerMonthBound = Math.floor(weeks / 4); // Approximating 4 weeks per month
  //       let upperMonthBound = lowerMonthBound + 1;
  //       return `${lowerMonthBound}-${upperMonthBound} months`;
  //     }
  //   }
  // }

  function convertHoursToTimeString(businessHours) {
    const HOURS_IN_DAY = 8; // Business hours in a day

    if (businessHours < HOURS_IN_DAY) {
      return "Less than 1 Business Day";
    } else {
      // Calculate the lower and upper bounds for business days
      let lowerDayBound = Math.floor(businessHours / HOURS_IN_DAY);
      let upperDayBound = Math.ceil(businessHours / HOURS_IN_DAY);

      // If the upper and lower bounds are the same, it means it's exactly that many days
      if (lowerDayBound === upperDayBound) {
        return `${lowerDayBound} Business Day(s)`;
      } else {
        // Provide a range of days
        return `${lowerDayBound}-${upperDayBound} Business Days`;
      }
    }
  }

  //Delivery Aggregation

  let DeliveryItemsNoTag = [];
  const primaryItemsDeliveryItems = Quote?.Material?.Items?.filter(
    (item) => item.Tag_Id == 0 && item.Parent_Id === 0
  );

  let totalAggregatedDeliveryCost = 0;

  primaryItemsDeliveryItems?.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      // Check deliveries for the untagged item itself
      Quote.Delivery.Tools.forEach((tool) => {
        if (o.Id === tool.Item_Id) {
          totalAggregatedDeliveryCost += tool.Price;
        }
      });

      // Check deliveries for the related items of the untagged item
    }
    if (o.RelatedItems) {
      o.RelatedItems.forEach((relatedItem) => {
        Quote.Delivery.Tools.forEach((tool) => {
          if (relatedItem.Id === tool.Item_Id) {
            totalAggregatedDeliveryCost += tool.Price;
          }
        });
      });
    }
  });

  primaryItemsDeliveryItems?.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      Quote.Delivery.Tools.forEach((tool) => {
        if (o.Id === tool.Item_Id) {
          DeliveryItemsNoTag.push(tool);
        }
      });
    }
    if (o.RelatedItems && o.RelatedItems.length > 0) {
      o.RelatedItems.forEach((relatedItem) => {
        Quote.Delivery.Tools.forEach((tool) => {
          if (relatedItem.Id === tool.Item_Id) {
            DeliveryItemsNoTag.push(tool);
          }
        });
      });
    }
  });

  let removalsMaterialTotal = 0;
  let removalsLaborTotal = 0;

  Quote?.Removal?.Items?.filter(
    (removalItem) => removalItem.Tag_Id === 0
  ).forEach((o) => {
    removalsMaterialTotal += o.Price;
    removalsLaborTotal += o.LaborSubtotal;
  });

  const finalUntaggedMaterialSubtotal =
    untaggedMaterialSubtotal +
    totalAggregatedDeliveryCost +
    removalsMaterialTotal;
  const finalUntaggedInstallSubtotal =
    untaggedInstallSubtotal + totalAggregatedDeliveryCost + removalsLaborTotal;
  const finalUntaggedTotalSubtotal =
    untaggedTotalSubtotal +
    totalAggregatedDeliveryCost +
    removalsMaterialTotal +
    removalsLaborTotal;

  //General Time

  // Declare variables to accumulate the total time in decimal hours
  let totalInstallTimeInDecimalHours = 0;
  let totalRemovalTimeInDecimalHours = 0;

  // Iterate through the material items to accumulate the installation time
  Quote?.Material?.Items?.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      const laborName = laborNamesByProduct[`${o.Name}_${o.Id}`] || "";
      const laborItem = Quote.Labor.Items.find(
        (item) => item.Name === laborName
      );
      const hourlyRate = laborItem ? laborItem.UnitPrice : 154;
      const installSub =
        displayStatus[o.Id] === true
          ? o.LaborSubtotal
          : o.LaborSubtotalWithRelatedItems;
      totalInstallTimeInDecimalHours += installSub / hourlyRate;
    }
  });

  // Iterate through the removal items to accumulate the removal time
  Quote?.Removal?.Items?.forEach((o) => {
    const removalTime = totalRemovalsByItem[`${o.Name}_${o.Id}`] || 0;
    totalRemovalTimeInDecimalHours += removalTime;
  });

  // Use `totalTimeString` in the subtotal section to display the total time

  const RemovalItemsNoTag =
    Quote?.Removal?.Items?.filter(
      (removalItem) => removalItem.Tag_Id === 0
    ).map((item) => {
      const originalRemovalIndex = Quote.Removal.Items.findIndex(
        (removal) => removal.Id === item.Id
      );
      const removalTime =
        totalRemovalsByItem[`${item.Name}_${originalRemovalIndex}`] || 0;
      return {
        ...item,
        removalTime: decimalHoursToTimeString(removalTime), // Add the time property
      };
    }) || []; // Default to an empty array if undefined

  // Calculate the total removal cost, quantity, etc.
  const totalRemovalCost = RemovalItemsNoTag.reduce(
    (sum, item) => sum + item.Price + item.LaborSubtotal,
    0
  );
  const totalRemovalQuantity = RemovalItemsNoTag.reduce(
    (sum, item) => sum + item.Quant,
    0
  );
  const totalRemovalTime = RemovalItemsNoTag.reduce((sum, item) => {
    const originalRemovalIndex = Quote.Removal.Items.findIndex(
      (removal) => removal.Id === item.Id
    );
    return (
      sum + (totalRemovalsByItem[`${item.Name}_${originalRemovalIndex}`] || 0)
    );
  }, 0);

  const [selectedTagId, setSelectedTagId] = useState(null);
  const [showQuoteLineItem, setShowQuoteLineItem] = useState(false);
  const [showToolLineItem, setShowToolLineItem] = useState(false);

  const [addOnSelectedItem, setAddOnSelectedItem] = useState({});

  const [deleteItemConfirmDisplay, setDeleteItemConfirmDisplay] =
    useState(false);
  const [deleteItemId, setDeleteItemId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Calculate the total time and convert it to a displayable format
  const totalTimeInDecimalHours =
    totalInstallTimeInDecimalHours + totalRemovalTimeInDecimalHours;
  const totalTimeString = decimalHoursToTimeString(
    totalTimeInDecimalHours + totalRemovalTime
  );

  const handleClick = (index) => {
    const element = document.querySelector(`[data-scroll-id="${index}"]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const SaveAndSendEstimate = async () => {
    try {
      const data = await UpdateQuote(Quote);
      if (data !== null) {
        const notify = await SetQuoteTo_Notify(params.quoteId);
        if (notify !== null) {
          window.alert("Homeowner Notified");
        }
      }
    } catch (error) {}
  };

  const SaveEstimateText = async () => {
    try {
      const data = await UpdateQuote(Quote);
      if (data !== null) {
        GetProposalInformation(params.quoteId);
      }
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  if (Quote !== null && Quote !== undefined) {
    if (Quote.Material !== null && Quote.Material !== undefined) {
      if (window.$ISMOBILE === true) {
        return (
          <Cont>
            <LeftCont>
              <Menu> </Menu>
            </LeftCont>
            <FlexColumn81vwLightGrey>
              <TopMentProposalView
                mobilePageProp={estimateName}
                currentPage={"estimatorView"}
                pageProp={projectName}
                estimateName={estimateName}
                homeownerName={homeownerName}
                projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
                scrollinfo={() => {
                  scrollToOverview.scrollIntoView();
                }}
                scrollsupply={() => {
                  scrollToSupply.scrollIntoView();
                }}
                scrollinstall={() => {
                  scrollToInstall.scrollIntoView();
                }}
                scrolldesigns={() => {
                  scrollToDesigns.scrollIntoView();
                }}
                scrollcomments={() => {
                  scrollToComments.scrollIntoView();
                }}
                scrollconfirmation={() => {
                  scrollToConfirm.scrollIntoView();
                }}
              ></TopMentProposalView>

              <FlexRowContFlexStartMobileColumn
                height="auto"
                display={
                  Quote.QuoteStatus === QuoteStatus.RevisionRequested
                    ? "none"
                    : "flex"
                }
              ></FlexRowContFlexStartMobileColumn>

              {/* Proposal Overview --------------------------------------------------------------- */}
              <RevisionInstructionsWindow
                open={revisionInstructionDisplay}
                CloseWindow={() => {
                  setRevisionInstructionDisplay(false);
                }}
                StartRevising={() => {
                  ChangeUIToMakeRevisions();
                  setRevisionInstructionDisplay(false);
                }}
              ></RevisionInstructionsWindow>

              <DuplicateProposalWindow
                quoteObj={Quote}
                onClose={() => {
                  setCloneProposalWindowDisplay(false);
                }}
                open={cloneProposalWindowDisplay}
              ></DuplicateProposalWindow>
              <ImageViewWindow
                open={imageGalleryDisplay}
                imgurl={imagePreviewUrl}
                onClose={() => {
                  setImageGalleryDisplay(false);
                  document.body.style.overflow = "unset";
                }}
              ></ImageViewWindow>

              <ContentCont77vw height="auto" width="75vw">
                {isLoading === true ? (
                  <Backdrop style={{ zIndex: 1000 }} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <div>
                    <LeagueSpartanLarge>Estimate Name</LeagueSpartanLarge>
                    <br />
                    <InputField
                      defaultValue={Quote?.Tittle}
                      onChange={(e) => {
                        Quote.Tittle = e.target.value;
                      }}
                    ></InputField>
                    <FlexColumnFullWidthMargins>
                      <br></br>
                      <FlexRowContFlexStart height="auto">
                        <LeagueSpartanLarge>
                          Estimate Details
                        </LeagueSpartanLarge>
                      </FlexRowContFlexStart>

                      <br></br>
                      <TextAreaAutoResize
                        id="text"
                        defaultValue={Quote.Notes}
                        onChange={(e) => {
                          Quote.Notes = e.target.value;
                        }}
                      ></TextAreaAutoResize>
                    </FlexColumnFullWidthMargins>
                    <br />
                  </div>
                )}
                <RegularResponsiveButton
                  onClick={() => {
                    setIsLoading(true);
                    SaveEstimateText(Quote);
                  }}
                  width="20%"
                >
                  Save Changes
                </RegularResponsiveButton>
              </ContentCont77vw>

              <ContentCont44Percent height="auto" justifycontent="space-around">
                <FlexColumnFullWidthMargins height="100%">
                  <br></br>
                  <FlexRowContFlexStart height="auto">
                    <LeagueSpartanLarge>{estimateName}</LeagueSpartanLarge>
                  </FlexRowContFlexStart>
                  <br></br>
                  <SummaryContainer height="100%" justifycontent="space-around">
                    {/* <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                   <LeagueSpartanHeaderSmall>Material Supply</LeagueSpartanHeaderSmall>
                    </SummaryContainerItems> */}
                    <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                      <MediumHeader>Materials:</MediumHeader>
                      <MediumHeader>
                        {" "}
                        $
                        {Quote?.Material?.Total?.Subtotal?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems
                      display={
                        Quote?.Delivery?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                      <MediumHeader>Deliveries:</MediumHeader>
                      <MediumHeader>
                        {" "}
                        $
                        {Quote?.Delivery?.Total?.Subtotal?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>

                    <br></br>

                    {/* <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                   <LeagueSpartanHeaderSmall>Installation</LeagueSpartanHeaderSmall>
                    </SummaryContainerItems> */}

                    <SummaryContainerItems
                      display={
                        Number(projectInstallSubtotal).toFixed(2) >
                        Stored_Values.MIN_LABOR
                          ? "flex"
                          : "none"
                      }
                    >
                      <MediumHeader>Labour:</MediumHeader>
                      <MediumHeader>
                        $
                        {Number(
                          projectInstallSubtotal + removalsHeader8Subtotal
                        )
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems
                      display={
                        Number(projectInstallSubtotal).toFixed(2) >
                        Stored_Values.MIN_LABOR
                          ? "none"
                          : "flex"
                      }
                    >
                      <MediumHeader>Minimum Labour Fee:</MediumHeader>
                      <MediumHeader>
                        ${Stored_Values?.MIN_LABOR?.toFixed(2)}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems>
                      <MediumHeader>
                        Machines, Disposals, Site Access
                      </MediumHeader>

                      <MediumHeader>
                        ${" "}
                        {(
                          parseFloat(
                            Quote?.Tool?.Total?.Subtotal?.toFixed(2).replace(
                              /,/g,
                              ""
                            )
                          ) +
                          parseFloat(
                            Quote?.AccessArea?.Total?.Subtotal?.toFixed(
                              2
                            ).replace(/,/g, "")
                          )
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    {/* <SummaryContainerItems
                      display={
                        'flex'
                      }
                    >
                      <MediumHeader>Removals:</MediumHeader>
                      <MediumHeader>
                        ${" "}
                        {((removalsHeader8Subtotal))
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems> */}

                    <StyledHRPropColour
                      border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                    ></StyledHRPropColour>
                    <SummaryContainerItems>
                      <LeagueSpartanHeaderSmall>
                        Project Subtotal:
                      </LeagueSpartanHeaderSmall>
                      <MediumHeader>
                        $
                        {
                          projectSubtotal
                          // .toFixed(2)
                          // .toString()
                          // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      </MediumHeader>
                    </SummaryContainerItems>

                    <SummaryContainerItems>
                      <MediumHeader>
                        {storeTaxes[0]?.TaxCode} ({storeTaxes[0]?.Rate}%)
                      </MediumHeader>
                      <MediumHeader>
                        $
                        {estimateGST
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>

                    {/*                     
                  <SummaryContainerItems>
                  <MediumHeader>
                      {storeTaxes[1]?.TaxCode} ({storeTaxes[1]?.Rate}%)
                    </MediumHeader>
                    <MediumHeader>
                      ${estimatePST.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </MediumHeader>
                    </SummaryContainerItems> */}
                    {/* {supplyTotal.Tax.map((o, i) => (
                      <SummaryContainerItems key={i}>
                        <MediumHeader>
                          Supply {o.TaxCode} ({o.Rate}%):
                        </MediumHeader>
                        <MediumHeader>
                          $
                          {o.Amount.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                    ))}

                    {installTotal.Tax.map((o, i) => (
                      <SummaryContainerItems
                        key={i}
                        display={installSubtotalDisplay}
                      >
                        <MediumHeader>
                          Install {o.TaxCode} ({o.Rate}%):
                        </MediumHeader>
                        <MediumHeader>
                          $
                          {o.Amount.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                    ))} */}
                    <StyledHRGreenBreak></StyledHRGreenBreak>
                    <SummaryContainerItems>
                      <LeagueSpartanRegular>
                        Project Total:
                      </LeagueSpartanRegular>
                      <LeagueSpartanRegular>
                        {" "}
                        $
                        {projectGrandTotal
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </LeagueSpartanRegular>
                    </SummaryContainerItems>
                    <SummaryContainerItems>
                      <LeagueSpartanRegular>
                        Projected Project Length:
                      </LeagueSpartanRegular>
                      <LeagueSpartanRegular>
                        {convertHoursToTimeString(
                          Quote.Labor.Items.reduce(
                            (total, o) => total + o?.Quant,
                            0
                          )
                        )}
                      </LeagueSpartanRegular>
                    </SummaryContainerItems>
                    <FlexColumnContCenterAlign>
                      <RegularResponsiveButton
                        fontsize="14px"
                        lineheight="30px"
                        width="95%"
                        height="30px"
                        display={showDepositButton}
                        onClick={() => {
                          ValidateAgreement(
                            supplyOnlySelected,
                            params.caseId,
                            params.quoteId
                          );
                        }}
                      >
                        Pay Deposit $
                        {deposit < Stored_Values.MIN_DEPOSIT
                          ? Stored_Values.MIN_DEPOSIT.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : deposit
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </RegularResponsiveButton>

                      <TinyTextItalics color={ColorPalette.DarkGrey}>
                        Project deposit is non-refundable. By paying the
                        deposit, you have agreed that you have read and
                        understood our{" "}
                        <span>
                          {" "}
                          <a
                            rel="noreferrer"
                            href="https://newrhodesconstruction.com/rl-terms-of-service/"
                            target="_blank"
                          >
                            {" "}
                            Terms of Service{" "}
                          </a>{" "}
                        </span>{" "}
                      </TinyTextItalics>
                    </FlexColumnContCenterAlign>
                  </SummaryContainer>
                </FlexColumnFullWidthMargins>
              </ContentCont44Percent>

              <ContentCont77vw>
                <FlexColumnFullWidth>
                  <FlexRowContFlexStart>
                    <LeagueSpartanLarge>Construction Plans</LeagueSpartanLarge>
                    <Icon30px30pxPointer
                      src="/Green Button.svg"
                      onClick={() => {
                        AddPhotoButtonClick();
                      }}
                    ></Icon30px30pxPointer>
                    <input
                      style={{ display: "none" }}
                      ref={inputFile}
                      type="file"
                      onChange={handleUpload}
                    />
                  </FlexRowContFlexStart>
                  <QuotePhotoUploadPreviewWindow
                    quoteObj={Quote}
                    open={previewImageWindowDisplay}
                    previewsrc={previewImage}
                    CancelUpload={() => {
                      ResetImage(previewImage);
                    }}
                    CloseWindow={() => {
                      setPreviewImageWindowDisplay(false);
                    }}
                    UploadSuccessful={() => {
                      GetProposalInformation(params.quoteId);
                    }}
                  ></QuotePhotoUploadPreviewWindow>
                  <br></br>
                  <GridContainer3Wide>
                    {Quote.Photos.map((o, i) => {
                      return o.File?.includes(".pdf") ||
                        o.File?.includes(".PDF") ? (
                        <FlexContainerPhotos key={i}>
                          <a
                            id={i}
                            rel="noreferrer"
                            href={o.File !== "" ? window.$IMGURL + o.File : ""}
                            target={"_blank"}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <embed
                              target={"_blank"}
                              src={"/pdficon.png"}
                              width={"150px"}
                              style={{ backgroundColor: "white" }}
                              height={"200px"}
                            ></embed>
                            <FlexContFullWidthHeightJustifyCenter>
                              <LargeText>{o.Caption}</LargeText>
                            </FlexContFullWidthHeightJustifyCenter>
                          </a>
                        </FlexContainerPhotos>
                      ) : (
                        <FlexContainerPhotos key={i}>
                          <Image375px
                            onClick={() => {
                              DisplayImageInGallery(o.File);
                            }}
                            src={o.File !== "" ? window.$IMGURL + o.File : ""}
                          ></Image375px>
                          <FlexContFullWidthHeightJustifyCenter>
                            <LargeText>{o.Caption}</LargeText>
                          </FlexContFullWidthHeightJustifyCenter>
                        </FlexContainerPhotos>
                      );
                    })}
                  </GridContainer3Wide>
                </FlexColumnFullWidth>
              </ContentCont77vw>

              <FlexRowContAutox100PercentMobileColumn
                alignitems="baseline"
                margin="0"
              >
                <ContentContQuote id="install">
                  <FlexColumnFullWidth>
                    <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                      <FlexColumnFullWidth mobilewidth="90vw">
                        <FlexRowContFlexStart>
                          <LeagueSpartanLarge>
                            Project Estimate
                          </LeagueSpartanLarge>

                          <TagWindow
                            tagObj={tagObj}
                            quoteid={Quote.Id}
                            open={tagWindowDisplay}
                            RefreshTaglist={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            onClose={() => {
                              setTagWindowDisplay(false);
                            }}
                          ></TagWindow>
                        </FlexRowContFlexStart>
                      </FlexColumnFullWidth>
                    </FlexRowCont100PercentSpaceBetween>
                  </FlexColumnFullWidth>

                  <FlexColumnFullWidth
                    display={installEstimateDisplay}
                    height="auto"
                    ref={ref2}
                  >
                    <AutoSizeOverflowY>
                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {Quote.Tags.map((t, j) => {
                        let MaterialSum = 0;
                        let InstallSum = 0;
                        let TotalSum = 0;
                        let sumHourlyRate = 0;
                        let countItems = 0;
                        let installSubSumByTag = {};
                        let timeSumByTag = {};
                        let removalTimeSubtotal = 0;

                        const RemovalItemsWithTag = Quote.Removal.Items.filter(
                          (removalItem) => removalItem.Tag_Id === t.Id
                        ).map((item) => {
                          const originalRemovalIndex =
                            Quote.Removal.Items.findIndex(
                              (removal) => removal.Id === item.Id
                            );
                          const removalTime =
                            totalRemovalsByItem[
                              `${item.Name}_${originalRemovalIndex}`
                            ] || 0;
                          return {
                            ...item,
                            removalTime: decimalHoursToTimeString(removalTime), // Add the time property
                          };
                        });
                        // Calculate the total removal cost, quantity, and time for the current tag
                        const totalRemovalCost = RemovalItemsWithTag.reduce(
                          (sum, item) => sum + item.Price + item.LaborSubtotal,
                          0
                        );
                        const totalRemovalTime = RemovalItemsWithTag.reduce(
                          (sum, item) => {
                            const originalRemovalIndex =
                              Quote.Removal.Items.findIndex(
                                (removal) => removal.Id === item.Id
                              );
                            return (
                              sum +
                              (totalRemovalsByItem[
                                `${item.Name}_${originalRemovalIndex}`
                              ] || 0)
                            );
                          },
                          0
                        );

                        const primaryItems = Quote.Material.Items.filter(
                          (item) => item.Tag_Id == t.Id && item.Parent_Id === 0
                        );

                        const matchedDeliveries = [];

                        primaryItems.forEach((primaryItem) => {
                          // Check for deliveries related to the primary item itself
                          Quote.Delivery.Tools.forEach((tool) => {
                            if (primaryItem.Id === tool.Item_Id) {
                              matchedDeliveries.push({
                                ...tool,
                                Tag_Id: primaryItem.Tag_Id,
                              });
                            }
                          });

                          // Check for deliveries related to the primary item's related items
                          if (
                            primaryItem.RelatedItems &&
                            primaryItem.RelatedItems.length > 0
                          ) {
                            primaryItem.RelatedItems.forEach((relatedItem) => {
                              Quote.Delivery.Tools.forEach((tool) => {
                                if (relatedItem.Id === tool.Item_Id) {
                                  matchedDeliveries.push({
                                    ...tool,
                                    Tag_Id: primaryItem.Tag_Id, // Assuming the tag ID is the same as the primary item's
                                  });
                                }
                              });
                            });
                          }
                        });

                        let totalAggregatedDeliveryCostWithTagId = 0;

                        primaryItems.forEach((primaryItem) => {
                          // Check deliveries for the primary item
                          Quote.Delivery.Tools.forEach((tool) => {
                            if (primaryItem.Id === tool.Item_Id) {
                              totalAggregatedDeliveryCostWithTagId +=
                                tool.Price;
                            }
                          });

                          // Check deliveries for the related items of the primary item
                          if (primaryItem.RelatedItems) {
                            primaryItem.RelatedItems.forEach((relatedItem) => {
                              Quote.Delivery.Tools.forEach((tool) => {
                                if (relatedItem.Id === tool.Item_Id) {
                                  totalAggregatedDeliveryCostWithTagId +=
                                    tool.Price;
                                }
                              });
                            });
                          }
                        });

                        primaryItems.forEach((o) => {
                          const materialSub = o.SubtotalWithRelatedItems;
                          const installSub = o.LaborSubtotalWithRelatedItems;
                          const originalIndex = Quote.Material.Items.findIndex(
                            (item) => item.Id === o.Id
                          );
                          const laborName = laborNamesByProduct[
                            `${o.Name}_${originalIndex}`
                          ]
                            ? Array.from(
                                laborNamesByProduct[
                                  `${o.Name}_${originalIndex}`
                                ]
                              )
                                .join(", ")
                                .split(", ")[0]
                            : "";
                          const laborItem = Quote.Labor.Items.find(
                            (item) => item.Name === laborName
                          );
                          const hourlyRate = laborItem
                            ? laborItem.UnitPrice
                            : 154;

                          sumHourlyRate += hourlyRate;
                          countItems++;

                          MaterialSum += materialSub;
                          InstallSum += installSub;
                          TotalSum += materialSub + installSub;
                        });

                        Quote.Removal.Items.filter(
                          (removalItem) => removalItem.Tag_Id === t.Id
                        ).forEach((o) => {
                          const removalTotal = o.Price + o.LaborSubtotal;
                          MaterialSum += removalTotal; // Assuming removals are treated as material costs
                          TotalSum += removalTotal;
                        });

                        MaterialSum += totalAggregatedDeliveryCostWithTagId;
                        TotalSum += totalAggregatedDeliveryCostWithTagId;

                        const averageHourlyRate =
                          countItems > 0 ? sumHourlyRate / countItems : 0;

                        return (
                          <div>
                            <br></br>
                            <DeleteConfirmWindow
                              closeDel={() => {
                                setDeleteTagConfirmDisplay(false);
                              }}
                              onDeleteFunction={() => {
                                DeleteTagOnQuote(deleteTagId, params.quoteId);
                              }}
                              deleteOpen={deleteTagConfirmDisplay}
                            ></DeleteConfirmWindow>
                            <MobileEstimateQuoteItem
                              isHeader={true}
                              header2={t.TagName}
                              ShowItemSelection={() => {
                                setSelectedTagId(t.Id);
                                setShowQuoteLineItem(true);
                                handleClick(t.Id);
                              }}
                              EditTag={() => {
                                setTagObj(t);
                                setTagWindowDisplay(true);
                              }}
                              DeleteTag={() => {
                                setDeleteTagId(t.Id);
                                setDeleteTagConfirmDisplay(true);
                              }}
                              bgColor={ColorPalette.White}
                            ></MobileEstimateQuoteItem>

                            <StyledHRGreenBreakThick></StyledHRGreenBreakThick>

                            {selectedTagId === t.Id && showQuoteLineItem ? (
                              <QuoteLineItem
                                data-scroll-id={t.Id}
                                onClose={() => {
                                  setShowQuoteLineItem(false);
                                  setSelectedTagId(0);
                                  setAddOnSelectedItem(null);
                                }}
                                RefreshItemList={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                Tag_Id={t.Id}
                                addOnSelectedItem={addOnSelectedItem}
                              ></QuoteLineItem>
                            ) : null}

                            {primaryItems.map((o, i) => {
                              const materialSub =
                                displayStatus[o.Id] === true
                                  ? o.Price
                                  : o.SubtotalWithRelatedItems;
                              const installSub =
                                displayStatus[o.Id] === true
                                  ? o.LaborSubtotal
                                  : o.LaborSubtotalWithRelatedItems;

                              const originalIndex =
                                Quote.Material.Items.findIndex(
                                  (item) => item.Id === o.Id
                                );

                              // Get the total labor hours for this item's name
                              const totalHours =
                                totalHoursByProduct[
                                  `${o.Name}_${originalIndex}`
                                ] || 0;

                              timeSumByTag[o.Tag_Id] +=
                                totalHoursByProduct[
                                  `${o.Name}_${originalIndex}`
                                ];
                              const laborNames = laborNamesByProduct[
                                `${o.Name}_${originalIndex}`
                              ]
                                ? Array.from(
                                    laborNamesByProduct[
                                      `${o.Name}_${originalIndex}`
                                    ]
                                  ).join(", ")
                                : "";
                              // Extract the labor name(s) from the laborNames variable
                              const laborName = laborNames
                                ? laborNames.split(", ")[0]
                                : ""; // Adjust as needed if multiple names

                              // Find the corresponding labor item in Quote.Labor.Items
                              const laborItem = Quote.Labor.Items.find(
                                (item) => item.Name === laborName
                              );

                              // Get the price from the labor item, or default to 175 if not found
                              const hourlyRate = laborItem
                                ? laborItem.UnitPrice
                                : 154;
                              sumHourlyRate += hourlyRate;
                              countItems++;

                              return (
                                <MobileEstimateQuoteItem
                                  noChange={o.NoChange}
                                  key={i}
                                  showRelatedItems={displayStatus[o.Id]}
                                  onToggleDisplay={() => toggleDisplay(o.Id)}
                                  RefreshComponentState={() => {
                                    GetProposalInformation(params.quoteId);
                                  }}
                                  inputheaderposition={headerInputShown}
                                  OutputValue={(value) => {
                                    UpdateQuoteItemQuantity(o.Id, value);
                                  }}
                                  showAddOnsSelection={() => {
                                    setShowQuoteLineItem(true);
                                    setSelectedTagId(t.Id);
                                    setAddOnSelectedItem(o);
                                  }}
                                  deleteItemFromEstimate={(itemId) => {
                                    if (itemId) {
                                      setDeleteItemId(itemId);
                                    } else {
                                      setDeleteItemId(o.Id);
                                    }
                                    setDeleteItemConfirmDisplay(true);
                                  }}
                                  quoteItemObj={o}
                                  parentid={o.Parent_Id}
                                  header1="Delete"
                                  header2={o.Name}
                                  subheader2notes={
                                    o.Notes == null ? "" : o.Notes + ""
                                  }
                                  subheader2={
                                    o.Description === null ? "" : o.Description
                                  }
                                  header3={
                                    Number(o?.Quant)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                    " " +
                                    o.DisplayUnit
                                  }
                                  header4={
                                    "$" +
                                    (o?.Quant !== 0
                                      ? (materialSub / o?.Quant).toFixed(4)
                                      : materialSub
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          ))
                                  }
                                  // header5={
                                  //   "$" +
                                  //   materialSub
                                  //     .toFixed(2)
                                  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  // }
                                  header5={
                                    "$" +
                                    (o?.Quant !== 0
                                      ? (installSub / o?.Quant).toFixed(4)
                                      : installSub
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          ))
                                  }
                                  // header7={
                                  //   "$" +
                                  //   installSub
                                  //     .toFixed(2)
                                  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  // }
                                  // header8={laborNames ? ` ${laborNames}` : ''}
                                  header7={decimalHoursToTimeString(
                                    installSub / hourlyRate
                                  )}
                                  hourlyRate={hourlyRate}
                                  // header9={decimalHoursToTimeString(totalHours)}
                                  header8={
                                    "$" +
                                    (
                                      parseFloat(materialSub.toFixed(2)) +
                                      parseFloat(installSub.toFixed(2))
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header3title="Quantity"
                                  header4title="Material Rate"
                                  header5title="Install Rate"
                                  header7title="Install Time"
                                ></MobileEstimateQuoteItem>
                              );
                            })}

                            {RemovalItemsWithTag.length > 0 ? (
                              <div key={`removal-tag`}>
                                <MobileEstimateQuoteItem
                                  CategoryType={CategoryTypes.Removal}
                                  header1="Delete"
                                  header2="Removal"
                                  RemovalItems={RemovalItemsWithTag}
                                  showRelatedItems={removalDisplayStatus[t.Id]}
                                  RefreshComponentState={() => {
                                    GetProposalInformation(params.quoteId);
                                  }}
                                  deleteItemFromEstimate={(itemId) => {
                                    if (itemId) {
                                      setDeleteItemId(itemId);
                                    } else {
                                      console.log("no item passed");
                                    }
                                    setDeleteItemConfirmDisplay(true);
                                  }}
                                  onToggleDisplay={() =>
                                    toggleRemovalDisplay(t.Id)
                                  }
                                  header7={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : "$" +
                                        totalRemovalCost
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header9={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : decimalHoursToTimeString(
                                          totalRemovalTime
                                        )
                                  }
                                  header8={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : "$" +
                                        totalRemovalCost
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                ></MobileEstimateQuoteItem>
                              </div>
                            ) : null}

                            {totalAggregatedDeliveryCostWithTagId > 0 ? (
                              <MobileEstimateQuoteItem
                                key={`tagged-aggregated-delivery`}
                                quoteItemObj={{
                                  Price: totalAggregatedDeliveryCostWithTagId,
                                }}
                                showRelatedItems={displayStatus[t.Id]}
                                RefreshComponentState={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                deleteItemFromEstimate={async (item_id) => {
                                  setIsLoading(true);
                                  await DeleteQuoteTool(item_id);
                                  GetProposalInformation(params.quoteId);
                                  setIsLoading(false);
                                }}
                                CategoryType={"Delivery"}
                                onToggleDisplay={() => toggleDisplay(t.Id)}
                                DeliveryItems={matchedDeliveries}
                                header1="Delete"
                                header2="Deliveries"
                                header3=""
                                header4=""
                                header8={
                                  displayStatus[t.Id] === true
                                    ? ""
                                    : "$" +
                                      totalAggregatedDeliveryCostWithTagId
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              ></MobileEstimateQuoteItem>
                            ) : null}
                          </div>
                        );
                      })}

                      <div>
                        <br></br>

                        {generalMaterialsHeader === "flex" ? (
                          <MobileEstimateQuoteItem
                            isHeader={true}
                            header2="General Materials"
                            ShowItemSelection={() => {
                              setShowQuoteLineItem(true);
                              setSelectedTagId(0);
                            }}
                            // header3="Quantity"
                            // header4="Material Rate"
                            // header5="Material Subtotal"
                            // header6="Install Rate"
                            // header7="Install Subtotal"
                            // header8="Line Subtotal"
                            bgColor={ColorPalette.White}
                          ></MobileEstimateQuoteItem>
                        ) : null}

                        <StyledHRGreenBreakThick
                          display={generalMaterialsHeader}
                        ></StyledHRGreenBreakThick>

                        {selectedTagId === 0 && showQuoteLineItem ? (
                          <QuoteLineItem
                            data-scroll-id={0}
                            onClose={() => {
                              setShowQuoteLineItem(false);
                              setSelectedTagId(0);
                              setAddOnSelectedItem(null);
                            }}
                            RefreshItemList={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            Tag_Id={0}
                            addOnSelectedItem={addOnSelectedItem}
                          ></QuoteLineItem>
                        ) : null}

                        {/* {Quote.Material.Items.map((o, i) => {
                          return o.Parent_Id === 0 && o.Tag_Id === 0 ? (
                            <MobileEstimateQuoteItem
                              key={i}
                              quoteItemObj={o}
                              inputheaderposition={headerInputShown}
                              OutputValue={(value) => {
                                UpdateQuoteItemQuantity(o.Id, value);
                              }}
                              noChange={o.NoChange}
                              parentid={o.Parent_Id}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={
                                o.Description === null ? "" : o.Description
                              }
                              subheader2notes={
                                o.Notes == null ? "" : o.Notes + ""
                              }
                              header3={o?.Quant.toFixed(2) + " " + o.DisplayUnit}
                              header4={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header5={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header6={
                                "$" + (o.LaborSubtotal / o?.Quant).toFixed(4)
                              }
                              header7={
                                "$" +
                                o.LaborSubtotal.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                (
                                  parseFloat(o.Price.toFixed(2)) +
                                  parseFloat(o.LaborSubtotal.toFixed(2))
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              header3title="Quantity"
                              header4title="Material Rate"
                              header5title="Material Subtotal"
                              header6title="Install Rate"
                              header7title="Install Subtotal"
                            ></MobileEstimateQuoteItem>
                          ) : null;
                        })} */}
                        {Quote?.Material?.Items.map((o, i) => {
                          if (o.Parent_Id === 0 && o.Tag_Id === 0) {
                            const materialSub =
                              displayStatus[o.Id] === true
                                ? o.Price
                                : o.SubtotalWithRelatedItems;
                            const installSub =
                              displayStatus[o.Id] === true
                                ? o.LaborSubtotal
                                : o.LaborSubtotalWithRelatedItems;

                            const matchedDeliveries = [];
                            Quote.Delivery.Tools.forEach((tool) => {
                              if (o.Id === tool.Item_Id) {
                                matchedDeliveries.push({
                                  ...tool,
                                  Tag_Id: o.Tag_Id,
                                });
                              }
                            });

                            let totalDeliveryCost = 0;
                            Quote.Delivery.Tools.forEach((tool) => {
                              if (o.Id === tool.Item_Id) {
                                totalDeliveryCost += tool.Price;
                              }
                            });

                            const originalIndex =
                              Quote.Material.Items.findIndex(
                                (item) => item.Id === o.Id
                              );
                            const totalHours =
                              totalHoursByProduct[
                                `${o.Name}_${originalIndex}`
                              ] || 0;
                            const laborNames = laborNamesByProduct[
                              `${o.Name}_${originalIndex}`
                            ]
                              ? Array.from(
                                  laborNamesByProduct[
                                    `${o.Name}_${originalIndex}`
                                  ]
                                ).join(", ")
                              : "";
                            const laborName = laborNames
                              ? laborNames.split(", ")[0]
                              : "";
                            const laborItem = Quote.Labor.Items.find(
                              (item) => item.Name === laborName
                            );
                            const hourlyRate = laborItem
                              ? laborItem.UnitPrice
                              : 154;

                            return (
                              <MobileEstimateQuoteItem
                                showRelatedItems={displayStatus[o.Id]}
                                onToggleDisplay={() => toggleDisplay(o.Id)}
                                RefreshComponentState={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                key={i}
                                quoteItemObj={o}
                                inputheaderposition={headerInputShown}
                                OutputValue={(value) =>
                                  UpdateQuoteItemQuantity(o.Id, value)
                                }
                                showAddOnsSelection={() => {
                                  setShowQuoteLineItem(true);
                                  setSelectedTagId(0);
                                  setAddOnSelectedItem(o);
                                }}
                                deleteItemFromEstimate={(itemId) => {
                                  if (itemId) {
                                    setDeleteItemId(itemId);
                                  } else {
                                    setDeleteItemId(o.Id);
                                  }
                                  setDeleteItemConfirmDisplay(true);
                                }}
                                noChange={o.NoChange}
                                parentid={o.Parent_Id}
                                header1="Delete"
                                header2={o.Name}
                                subheader2={o.Description || ""}
                                subheader2notes={o.Notes || ""}
                                header3={
                                  Number(o?.Quant)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                  " " +
                                  o.DisplayUnit
                                }
                                header4={
                                  "$" +
                                  (o?.Quant !== 0
                                    ? (materialSub / o?.Quant).toFixed(4)
                                    : materialSub
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                }
                                header5={
                                  "$" +
                                  (o?.Quant !== 0
                                    ? (installSub / o?.Quant).toFixed(4)
                                    : installSub
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                }
                                // header6={"$" + (o?.Quant ? (installSub / o?.Quant).toFixed(4) : installSub.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                                // header7={"$" + installSub.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                header7={decimalHoursToTimeString(
                                  installSub / hourlyRate
                                )}
                                header8={
                                  "$" +
                                  (
                                    parseFloat(materialSub.toFixed(2)) +
                                    parseFloat(installSub.toFixed(2))
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                hourlyRate={hourlyRate}
                                header3title="Quantity"
                                header4title="Material Rate"
                                header5title="Install Rate"
                                header7title="Install Time"
                              ></MobileEstimateQuoteItem>
                            );
                          }

                          return null;
                        }).concat(
                          <div key={`removal-no-tag`}>
                            <MobileEstimateQuoteItem
                              CategoryType={CategoryTypes.Removal}
                              deleteItemFromEstimate={(itemId) => {
                                if (itemId) {
                                  setDeleteItemId(itemId);
                                } else {
                                  setDeleteItemId(o.Id);
                                }
                                setDeleteItemConfirmDisplay(true);
                              }}
                              RefreshComponentState={() => {
                                GetProposalInformation(params.quoteId);
                              }}
                              header1="Delete"
                              header2="Removal"
                              RemovalItems={RemovalItemsNoTag}
                              showRelatedItems={removalDisplayStatus}
                              onToggleDisplay={() => {
                                setRemovalDisplayStatus(!removalDisplayStatus);
                              }}
                              // header3={totalRemovalQuantity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              // header6={removalDisplayStatus === false ?  "$" + (totalRemovalCost / totalRemovalQuantity).toFixed(4): ''}
                              // header7={removalDisplayStatus === false ?  "$" + totalRemovalCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                              header9={
                                removalDisplayStatus === true
                                  ? decimalHoursToTimeString(totalRemovalTime)
                                  : ""
                              }
                              header8={
                                removalDisplayStatus === true
                                  ? "$" +
                                    totalRemovalCost
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""
                              }
                            ></MobileEstimateQuoteItem>
                          </div>,
                          totalAggregatedDeliveryCost > 0 ? (
                            <MobileEstimateQuoteItem
                              key={`total-aggregated-delivery`}
                              quoteItemObj={{
                                Price: totalAggregatedDeliveryCost,
                              }}
                              DeliveryItems={DeliveryItemsNoTag}
                              CategoryType={"Delivery"}
                              RefreshComponentState={() => {
                                GetProposalInformation(params.quoteId);
                              }}
                              showRelatedItems={deliveryDisplayStatus}
                              onToggleDisplay={() => {
                                setDeliveryDisplayStatus(
                                  !deliveryDisplayStatus
                                );
                              }}
                              header1="Delete"
                              header2="Deliveries"
                              header3=""
                              header4=""
                              header8={
                                deliveryDisplayStatus === false
                                  ? "$" +
                                    totalAggregatedDeliveryCost
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""
                              }
                            ></MobileEstimateQuoteItem>
                          ) : null
                        )}
                      </div>
                      {toolsmachinesHeader === "flex" ? (
                        <div>
                          <MobileEstimateQuoteItem
                            isHeader={true}
                            ShowItemSelection={() => {
                              setSelectedTagId(0);
                              setShowToolLineItem(true);
                            }}
                            header2="Machines, Disposals, & Access"
                            header7="Move Cost Per Machine"
                            bgColor={ColorPalette.White}
                          ></MobileEstimateQuoteItem>
                          <FlexColumnCont100xAutoPercent>
                            <FlexColumnDiv>
                              <TinyTextItalics>Length</TinyTextItalics>
                              <InputField
                                defaultValue={Quote?.AccessArea?.Length}
                                onChange={(e) => {
                                  setProposal((prevQuote) => ({
                                    ...prevQuote,
                                    AccessArea: {
                                      ...prevQuote.AccessArea,
                                      Length: e.target.value,
                                    },
                                  }));
                                }}
                              />
                            </FlexColumnDiv>
                            <FlexColumnDiv>
                              <TinyTextItalics>Width</TinyTextItalics>
                              <InputField
                                defaultValue={Quote?.AccessArea?.Width}
                                onChange={(e) => {
                                  setProposal((prevQuote) => ({
                                    ...prevQuote,
                                    AccessArea: {
                                      ...prevQuote.AccessArea,
                                      Width: e.target.value,
                                    },
                                  }));
                                }}
                              />
                            </FlexColumnDiv>
                            <FlexColumnDiv>
                              <TinyTextItalics>Elevation</TinyTextItalics>
                              <InputField
                                defaultValue={Quote?.AccessArea?.Elevation}
                                onChange={(e) => {
                                  setProposal((prevQuote) => ({
                                    ...prevQuote,
                                    AccessArea: {
                                      ...prevQuote.AccessArea,
                                      Elevation: e.target.value,
                                    },
                                  }));
                                }}
                              />
                            </FlexColumnDiv>
                            <FlexColumnDiv>
                              <TinyTextItalics>Obstruction</TinyTextItalics>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    name="obstruct"
                                    value="true"
                                    checked={
                                      Quote?.AccessArea?.Obstruct === true
                                    }
                                    onChange={() => {
                                      setProposal((prevQuote) => ({
                                        ...prevQuote,
                                        AccessArea: {
                                          ...prevQuote.AccessArea,
                                          Obstruct: true,
                                        },
                                      }));
                                    }}
                                  />
                                  True
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="obstruct"
                                    value="false"
                                    checked={
                                      Quote?.AccessArea?.Obstruct === false
                                    }
                                    onChange={() => {
                                      setProposal((prevQuote) => ({
                                        ...prevQuote,
                                        AccessArea: {
                                          ...prevQuote.AccessArea,
                                          Obstruct: false,
                                        },
                                      }));
                                    }}
                                  />
                                  False
                                </label>
                              </div>
                            </FlexColumnDiv>
                            <br></br>

                            <RegularResponsiveButton
                              onClick={() => {
                                setIsLoading(true);
                                SaveEstimateText(Quote);
                              }}
                              width="20%"
                            >
                              Save Changes
                            </RegularResponsiveButton>

                            <br></br>
                          </FlexColumnCont100xAutoPercent>
                        </div>
                      ) : null}

                      {selectedTagId === 0 && showToolLineItem ? (
                        <QuoteLineItem
                          onClose={() => {
                            setShowToolLineItem(false);
                            setSelectedTagId(0);
                            setAddOnSelectedItem(null);
                          }}
                          RefreshItemList={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          Tag_Id={0}
                          addOnSelectedItem={addOnSelectedItem}
                        ></QuoteLineItem>
                      ) : null}

                      <StyledHRGreenBreakThick
                        mobiledisplay={toolsmachinesHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Tool.Tools.map((o, i) => {
                        if (!o?.ToolName.includes("Wheelbarrow")) {
                          if (o.RentalRate > 0 && o.BasePrice > 0) {
                            toolsHeader7Subtotal += o.BasePrice;
                          }
                          toolsHeader8Subtotal += o.Price;
                        }

                        return (
                          <MobileEstimateQuoteItem
                            key={i}
                            CategoryType={"Tool"}
                            RefreshComponentState={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            header1="Delete"
                            header2={o.ToolName}
                            subheader2={o.Notes !== null ? o.Notes : ""}
                            header4={
                              o.RentalRate > 0
                                ? "$" +
                                  (o.RentalRate * o.RentalLength + o.BasePrice)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                            }
                            deleteItemFromEstimate={async (itemId) => {
                              if (itemId) {
                                setIsLoading(true);
                                setDeleteItemId(itemId);
                                const data = await DeleteQuoteTool(itemId);
                                if (data === null) {
                                  GetProposalInformation(params.quoteId);
                                  setIsLoading(false);
                                }
                              } else {
                                setDeleteItemId(o.Id);
                                const data = await DeleteQuoteTool(o.Id);
                                if (data === null) {
                                  GetProposalInformation(params.quoteId);
                                  setIsLoading(false);
                                }
                              }
                            }}
                            quoteItemObj={o}
                            header5={o.RentalLength}
                            itemLink={o.VideoUrl}
                            header3={
                              typeof o?.Quant === "number" && !isNaN(o.Quant)
                                ? o.Quant.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : ""
                            }
                            // header8={
                            //   o.RentalRate > 0 && o.BasePrice > 0
                            //     ? "$" +
                            //       o.BasePrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            //     : ""
                            // }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header3title={"Quantity"}
                            header4title={"Rental Rate"}
                            header5title={"Rental Hours"}
                          ></MobileEstimateQuoteItem>
                        );
                      })}

                      {Quote.AccessArea.Items.map((o, i) => (
                        <div>
                          <MobileEstimateQuoteItem
                            key={i}
                            showRelatedItems={showRelated}
                            onClick={() => {
                              setShowRelated(!showRelated);
                            }}
                            RefreshComponentState={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            deleteItemFromEstimate={(itemId) => {
                              if (itemId) {
                                setDeleteItemId(itemId);
                              } else {
                                setDeleteItemId(o.Id);
                              }
                              setDeleteItemConfirmDisplay(true);
                            }}
                            quoteItemObj={o}
                            CategoryType={CategoryTypes.Access}
                            header1="Delete"
                            header2={o.Name}
                            subheader2={o.Notes}
                            header3={
                              Number(o?.Quant)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " " +
                              o.DisplayUnit
                            }
                            header5=""
                            header4=""
                            // header6={
                            //   "$" +
                            //   o.UnitPrice.toFixed(2).replace(
                            //     /\B(?=(\d{3})+(?!\d))/g,
                            //     ","
                            //   ) +
                            //   "" +
                            //   o.DisplayUnit
                            // }
                            // header7={
                            //   "$" +
                            //   o.Price.toFixed(2).replace(
                            //     /\B(?=(\d{3})+(?!\d))/g,
                            //     ","
                            //   )
                            // }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header3title={"Quantity"}
                          ></MobileEstimateQuoteItem>
                        </div>
                      ))}

                      <LeagueSpartanRegular display={laborDetailToggleView}>
                        Labour Breakdown (Staff Only)
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={laborDetailToggleView}
                      ></StyledHRGreenBreakThick>

                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {laborDetailToggleView === "flex"
                        ? Quote.Labor.Items.map((o, i) => (
                            <MobileEstimateQuoteItem
                              key={i}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={o.Notes}
                              header6={
                                o?.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                ) + " hr"
                              }
                              header7={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                            ></MobileEstimateQuoteItem>
                          ))
                        : null}

                      <br></br>
                    </AutoSizeOverflowY>
                  </FlexColumnFullWidth>
                </ContentContQuote>
              </FlexRowContAutox100PercentMobileColumn>
            </FlexColumn81vwLightGrey>

            <FixedIconToTop
              src="/BackToTopArrowAlt.svg"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            ></FixedIconToTop>
          </Cont>
        );
      }
      /////////////////////////// MOBILE ->>>>>>>>>>>>>

      /////////////////////////// DESKTOP ->>>>>>>>>>>>>

      return (
        <Cont>
          <LeftCont width="16vw" id="exclude-from-pdf">
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey
            width="85vw"
            minheight="auto"
            height="fit-content"
            id="estimate"
          >
            {/* <div id='exclude-from-pdf' style={{height: "100%", position: "relative"}}> */}
            <TopMentProposalView
              id="exclude-from-pdf"
              quoteObj={Quote}
              currentPage={"estimatorView"}
              mobilePageProp={estimateName}
              pageProp={projectName}
              estimateName={estimateName}
              homeownerName={homeownerName}
              projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
              onBack={() => {
                RouteToPrevious(userinfo.Role);
              }}
              saveAndSend={() => {
                SaveAndSendEstimate();
              }}
              saveEstimate={() => {
                SaveEstimateText();
              }}
              scrollinfo={() => {
                scrollToOverview.scrollIntoView();
              }}
              scrollsupply={() => {
                scrollToSupply.scrollIntoView();
              }}
              scrollinstall={() => {
                scrollToInstall.scrollIntoView();
              }}
              scrolldesigns={() => {
                scrollToDesigns.scrollIntoView();
              }}
              scrollcomments={() => {
                scrollToComments.scrollIntoView();
              }}
              scrollconfirmation={() => {
                scrollToConfirm.scrollIntoView();
              }}
            ></TopMentProposalView>
            {/* </div> */}
            {/* Approval --------------------------------------------------------------- */}
            <FlexRowContFlexStartMobileColumn
              height="auto"
              display={
                Quote.QuoteStatus === QuoteStatus.RevisionRequested
                  ? "none"
                  : "flex"
              }
            >
              <FlexRowCont alignitems="stretch" height="auto" width="100%">
                {/* <ContentCont77vw height='auto' width="75vw" id='exclude-from-pdf'>
                {isLoading === true ? (
            <Backdrop style={{ zIndex: 1000 }} open>
            <CircularProgress color="inherit" />
            </Backdrop>
                ) :
                <div>
              <LeagueSpartanLarge>
                        Estimate Name
                        </LeagueSpartanLarge>
                      
                        <br/>
                        <InputField defaultValue={Quote?.Tittle
                        }
                        onChange={(e) => { Quote.Tittle = e.target.value;}} 
                        >
                        </InputField>
                <FlexColumnFullWidthMargins>
                  <br></br>
                  <FlexRowContFlexStart height="auto">
          
                    <LeagueSpartanLarge>Estimate Details</LeagueSpartanLarge>
                  </FlexRowContFlexStart>
                  
                  <br></br>
  <TextAreaAutoResize
                  id="text"
                  defaultValue={Quote.Notes}
                  onChange={(e) => { Quote.Notes = e.target.value;}}
                ></TextAreaAutoResize>
                </FlexColumnFullWidthMargins>
                <br/>
            
                  </div>
                }

              </ContentCont77vw> */}
                <ContentCont77vw
                  display="none"
                  id="include-on-pdf"
                  width="75vw"
                  height="auto"
                  justifycontent="space-between"
                >
                  <FlexColumnFullWidthMargins
                    height="auto"
                    justifycontent="flex-start"
                  >
                    <Icon200x200 src="/NRCLogoRegularBlack.png"></Icon200x200>
                    <div>
                      <SmallHeader>New Rhodes Construction</SmallHeader>
                      <SmallHeader>
                        12306 McTavish Road Pitt Meadows BC V3Y1Z1
                      </SmallHeader>
                      <SmallHeader>+1 (778-657-5944)</SmallHeader>
                      <SmallHeader>ar@newrhodesconstruction.com</SmallHeader>
                      <SmallHeader>www.newrhodesconstruction.com</SmallHeader>
                      <SmallHeader>
                        GST/HST Registration No. 781153283RT0001
                      </SmallHeader>
                    </div>
                  </FlexColumnFullWidthMargins>

                  <FlexColumnFullWidthMargins
                    height="auto"
                    justifycontent="flex-start"
                  >
                    <div>
                      <SmallHeader>
                        <b> BILL TO: </b> {Case?.Name}
                      </SmallHeader>
                      <SmallHeader>{Case?.Email}</SmallHeader>
                      <SmallHeader>
                        {Case?.Street} {", "}
                        {Case?.City}
                      </SmallHeader>
                      <SmallHeader>{Case?.Phone}</SmallHeader>
                      <SmallHeader>
                        Date: {formatDate(Quote?.EditTime)}
                      </SmallHeader>
                      <br></br>
                      <TinyTextItalics color={ColorPalette.DarkGrey}>
                        Project deposit is non-refundable. By paying the
                        deposit, you have agreed that you have read and
                        understood our{" "}
                        <span>
                          {" "}
                          <a
                            rel="noreferrer"
                            href="https://newrhodesconstruction.com/rl-terms-of-service/"
                            target="_blank"
                          >
                            {" "}
                            Terms of Service{" "}
                          </a>{" "}
                        </span>{" "}
                      </TinyTextItalics>
                    </div>
                  </FlexColumnFullWidthMargins>
                </ContentCont77vw>
                <ContentCont77vw
                  width="75vw"
                  height="auto"
                  justifycontent="space-around"
                >
                  <FlexColumnFullWidthMargins height="100%">
                    <br></br>
                    <FlexRowContFlexStart height="auto">
                      <LeagueSpartanLarge>{estimateName}</LeagueSpartanLarge>
                      <RegularResponsiveButton
                        id="exclude-from-pdf"
                        display={labourBreakdownDisplay}
                        onClick={downloadPDF}
                      >
                        Download PDF
                      </RegularResponsiveButton>
                      <RegularResponsiveButton width="175px">
                        <CSVLink
                          data={materialItems}
                          filename={"Material.csv"}
                          style={{ color: "white" }}
                        >
                          Download Material CSV
                        </CSVLink>
                      </RegularResponsiveButton>
                      <br />
                      <RegularResponsiveButton width="175px">
                        <CSVLink
                          data={laborItems}
                          filename={"Labor.csv"}
                          style={{ color: "white" }}
                        >
                          Download Labor CSV
                        </CSVLink>
                      </RegularResponsiveButton>
                      <br />
                      <RegularResponsiveButton width="175px">
                        <CSVLink
                          data={removalItems}
                          filename={"Removal.csv"}
                          style={{ color: "white" }}
                        >
                          Download Removal CSV
                        </CSVLink>
                      </RegularResponsiveButton>
                    </FlexRowContFlexStart>
                    <SummaryContainer
                      height="100%"
                      justifycontent="space-around"
                    >
                      <SummaryContainerItems
                        display={
                          Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <LeagueSpartanHeaderSmall>
                          Material Supply
                        </LeagueSpartanHeaderSmall>
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <MediumHeader>Materials:</MediumHeader>
                        <MediumHeader>
                          {" "}
                          $
                          {Quote?.Material?.Total?.Subtotal?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Quote?.Delivery?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <MediumHeader>Delivery:</MediumHeader>
                        <MediumHeader>
                          {" "}
                          $
                          {Quote?.Delivery?.Total?.Subtotal?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>

                      <br></br>

                      <SummaryContainerItems
                        display={
                          Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <LeagueSpartanHeaderSmall>
                          Installation
                        </LeagueSpartanHeaderSmall>
                      </SummaryContainerItems>

                      <SummaryContainerItems
                        display={
                          Number(projectInstallSubtotal).toFixed(2) >
                          Stored_Values.MIN_LABOR
                            ? "flex"
                            : "none"
                        }
                      >
                        <MediumHeader>Labour:</MediumHeader>
                        <MediumHeader key={removalsHeader8Subtotal}>
                          $
                          {Number(
                            projectInstallSubtotal + removalsHeader8Subtotal
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Number(projectInstallSubtotal).toFixed(2) >
                          Stored_Values.MIN_LABOR
                            ? "none"
                            : "flex"
                        }
                      >
                        <MediumHeader>Minimum Labour Fee:</MediumHeader>
                        <MediumHeader>
                          ${Stored_Values?.MIN_LABOR?.toFixed(2)}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems>
                        <MediumHeader>
                          Machines, Disposals, Site Access
                        </MediumHeader>

                        <MediumHeader>
                          ${" "}
                          {(
                            parseFloat(
                              Quote?.Tool?.Total?.Subtotal?.toFixed(2).replace(
                                /,/g,
                                ""
                              )
                            ) +
                            parseFloat(
                              Quote?.AccessArea?.Total?.Subtotal?.toFixed(
                                2
                              ).replace(/,/g, "")
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      {/* <SummaryContainerItems
                    >
                      <MediumHeader>Removals:</MediumHeader>
                      <MediumHeader>
                        ${" "}
                        {((removalsHeader8Subtotal))
                        .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems> */}

                      <StyledHRPropColour
                        border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                      ></StyledHRPropColour>
                      <SummaryContainerItems>
                        <LeagueSpartanHeaderSmall>
                          Project Subtotal:
                        </LeagueSpartanHeaderSmall>
                        <MediumHeader>${projectSubtotal}</MediumHeader>
                      </SummaryContainerItems>

                      <SummaryContainerItems>
                        <MediumHeader>
                          {storeTaxes[0]?.TaxCode} ({storeTaxes[0]?.Rate}%)
                        </MediumHeader>
                        <MediumHeader>
                          $
                          {estimateGST
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      {/* <SummaryContainerItems>
                  <MediumHeader>
                      {storeTaxes[1]?.TaxCode} ({storeTaxes[1]?.Rate}%)
                    </MediumHeader>
                    <MediumHeader>
                      ${estimatePST.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </MediumHeader>
                    </SummaryContainerItems> */}
                      <StyledHRGreenBreak></StyledHRGreenBreak>
                      <SummaryContainerItems>
                        <LeagueSpartanRegular>
                          Project Total:
                        </LeagueSpartanRegular>
                        <LeagueSpartanRegular>
                          {" "}
                          $
                          {projectGrandTotal
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </LeagueSpartanRegular>
                      </SummaryContainerItems>
                      <SummaryContainerItems>
                        <LeagueSpartanRegular>
                          Projected Project Length:
                        </LeagueSpartanRegular>
                        <LeagueSpartanRegular>
                          {convertHoursToTimeString(
                            Quote.Labor.Items.reduce(
                              (total, o) => total + o?.Quant,
                              0
                            )
                          )}
                        </LeagueSpartanRegular>
                      </SummaryContainerItems>
                      <FlexColumnContCenterAlign id="exclude-from-pdf"></FlexColumnContCenterAlign>
                    </SummaryContainer>
                  </FlexColumnFullWidthMargins>
                </ContentCont77vw>
              </FlexRowCont>
            </FlexRowContFlexStartMobileColumn>

            {/* Proposal Overview --------------------------------------------------------------- */}
            <RevisionInstructionsWindow
              open={revisionInstructionDisplay}
              CloseWindow={() => {
                setRevisionInstructionDisplay(false);
              }}
              StartRevising={() => {
                ChangeUIToMakeRevisions();
                setRevisionInstructionDisplay(false);
              }}
            ></RevisionInstructionsWindow>

            <ImageViewWindow
              open={imageGalleryDisplay}
              imgurl={imagePreviewUrl}
              onClose={() => {
                setImageGalleryDisplay(false);
                document.body.style.overflow = "unset";
              }}
            ></ImageViewWindow>
            <DuplicateProposalWindow
              quoteObj={Quote}
              onClose={() => {
                setCloneProposalWindowDisplay(false);
              }}
              open={cloneProposalWindowDisplay}
            ></DuplicateProposalWindow>

            <ContentCont77vw id="exclude-from-pdf">
              <FlexColumnFullWidth>
                <FlexRowContFlexStart>
                  <LeagueSpartanLarge>Construction Plans</LeagueSpartanLarge>
                  <Icon30px30pxPointer
                    src="/Green Button.svg"
                    onClick={() => {
                      AddPhotoButtonClick();
                    }}
                  ></Icon30px30pxPointer>
                  <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    type="file"
                    onChange={handleUpload}
                  />
                  <QuotePhotoUploadPreviewWindow
                    quoteObj={Quote}
                    open={previewImageWindowDisplay}
                    previewsrc={previewImage}
                    CancelUpload={() => {
                      ResetImage(previewImage);
                    }}
                    CloseWindow={() => {
                      setPreviewImageWindowDisplay(false);
                    }}
                    UploadSuccessful={() => {
                      GetProposalInformation(params.quoteId);
                    }}
                  ></QuotePhotoUploadPreviewWindow>
                </FlexRowContFlexStart>

                <br></br>
                <FlexRowCont60PercentSpaceEvenly
                  alignitems="baseline"
                  width="100%"
                  flexwrap="wrap"
                >
                  {Quote.Photos.map((o, i) => {
                    return o.File?.includes(".pdf") ||
                      o.File?.includes(".PDF") ? (
                      <FlexContainerItem key={i}>
                        <a
                          id={i}
                          rel="noreferrer"
                          href={o.File !== "" ? window.$IMGURL + o.File : ""}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          <embed
                            target={"_blank"}
                            href={o.File !== "" ? window.$IMGURL + o.File : ""}
                            src={
                              o.File !== ""
                                ? window.$IMGURL +
                                  o.File +
                                  "#toolbar=0&navpanes=0&scrollbar=0"
                                : ""
                            }
                            width={"350px"}
                            style={{ backgroundColor: "white" }}
                            height={"200px"}
                          ></embed>
                          <OverrideEmbedDiv>
                            <OverrideEmbedImage></OverrideEmbedImage>
                          </OverrideEmbedDiv>
                          <FlexContFullWidthHeightJustifyCenter>
                            <LargeText>{o.Caption}</LargeText>
                          </FlexContFullWidthHeightJustifyCenter>
                        </a>
                      </FlexContainerItem>
                    ) : (
                      <FlexContainerItem key={i}>
                        <Image375px
                          onClick={() => {
                            DisplayImageInGallery(o.File);
                          }}
                          src={o.File !== "" ? window.$IMGURL + o.File : ""}
                        ></Image375px>
                        <FlexContFullWidthHeightJustifyCenter>
                          <LargeText>{o.Caption}</LargeText>
                        </FlexContFullWidthHeightJustifyCenter>
                      </FlexContainerItem>
                    );
                  })}
                </FlexRowCont60PercentSpaceEvenly>
              </FlexColumnFullWidth>
            </ContentCont77vw>

            <FlexRowContAutox100PercentMobileColumn
              alignitems="baseline"
              margin="0"
            >
              <ContentContQuote id="install">
                <FlexColumnFullWidth
                  display={installEstimateDisplay}
                  height="auto"
                  ref={ref2}
                >
                  <AutoSizeOverflowY>
                    <FlexDivMargins>
                      <LeagueSpartanRegular marginright="10px">
                        Project Estimate
                      </LeagueSpartanRegular>
                    </FlexDivMargins>

                    <TagWindow
                      tagObj={tagObj}
                      quoteid={Quote.Id}
                      open={tagWindowDisplay}
                      RefreshTaglist={() => {
                        GetProposalInformation(params.quoteId);
                      }}
                      onClose={() => {
                        setTagWindowDisplay(false);
                      }}
                    ></TagWindow>

                    {Quote.Tags.map((t, j) => {
                      let MaterialSum = 0;
                      let InstallSum = 0;
                      let TotalSum = 0;
                      let sumHourlyRate = 0;
                      let countItems = 0;
                      let installSubSumByTag = {};
                      let timeSumByTag = {};
                      let removalTimeSubtotal = 0;

                      // Filter removal items for the current tag
                      const RemovalItemsWithTag = Quote.Removal.Items.filter(
                        (removalItem) => removalItem.Tag_Id === t.Id
                      ).map((item) => {
                        const originalRemovalIndex =
                          Quote.Removal.Items.findIndex(
                            (removal) => removal.Id === item.Id
                          );
                        const removalTime =
                          totalRemovalsByItem[
                            `${item.Name}_${originalRemovalIndex}`
                          ] || 0;
                        return {
                          ...item,
                          removalTime: decimalHoursToTimeString(removalTime), // Add the time property
                        };
                      });
                      // Calculate the total removal cost, quantity, and time for the current tag
                      const totalRemovalCost = RemovalItemsWithTag.reduce(
                        (sum, item) => sum + item.Price + item.LaborSubtotal,
                        0
                      );
                      const totalRemovalTime = RemovalItemsWithTag.reduce(
                        (sum, item) => {
                          const originalRemovalIndex =
                            Quote.Removal.Items.findIndex(
                              (removal) => removal.Id === item.Id
                            );
                          return (
                            sum +
                            (totalRemovalsByItem[
                              `${item.Name}_${originalRemovalIndex}`
                            ] || 0)
                          );
                        },
                        0
                      );

                      const primaryItems = Quote.Material.Items.filter(
                        (item) => item.Tag_Id == t.Id && item.Parent_Id === 0
                      );

                      const matchedDeliveries = [];

                      primaryItems.forEach((primaryItem) => {
                        // Check for deliveries related to the primary item itself
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (primaryItem.Id === tool.Item_Id) {
                            matchedDeliveries.push({
                              ...tool,
                              Tag_Id: primaryItem.Tag_Id,
                            });
                          }
                        });

                        // Check for deliveries related to the primary item's related items
                        if (
                          primaryItem.RelatedItems &&
                          primaryItem.RelatedItems.length > 0
                        ) {
                          primaryItem.RelatedItems.forEach((relatedItem) => {
                            Quote.Delivery.Tools.forEach((tool) => {
                              if (relatedItem.Id === tool.Item_Id) {
                                matchedDeliveries.push({
                                  ...tool,
                                  Tag_Id: primaryItem.Tag_Id, // Assuming the tag ID is the same as the primary item's
                                });
                              }
                            });
                          });
                        }
                      });

                      let totalAggregatedDeliveryCostWithTagId = 0;

                      primaryItems.forEach((primaryItem) => {
                        // Check deliveries for the primary item
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (primaryItem.Id === tool.Item_Id) {
                            totalAggregatedDeliveryCostWithTagId += tool.Price;
                          }
                        });

                        // Check deliveries for the related items of the primary item
                        if (primaryItem.RelatedItems) {
                          primaryItem.RelatedItems.forEach((relatedItem) => {
                            Quote.Delivery.Tools.forEach((tool) => {
                              if (relatedItem.Id === tool.Item_Id) {
                                totalAggregatedDeliveryCostWithTagId +=
                                  tool.Price;
                              }
                            });
                          });
                        }
                      });

                      primaryItems.forEach((o) => {
                        const materialSub = o.SubtotalWithRelatedItems;
                        const installSub = o.LaborSubtotalWithRelatedItems;
                        const originalIndex = Quote.Material.Items.findIndex(
                          (item) => item.Id === o.Id
                        );
                        const laborName = laborNamesByProduct[
                          `${o.Name}_${originalIndex}`
                        ]
                          ? Array.from(
                              laborNamesByProduct[`${o.Name}_${originalIndex}`]
                            )
                              .join(", ")
                              .split(", ")[0]
                          : "";
                        const laborItem = Quote.Labor.Items.find(
                          (item) => item.Name === laborName
                        );
                        const hourlyRate = laborItem
                          ? laborItem.UnitPrice
                          : 154;

                        sumHourlyRate += hourlyRate;
                        countItems++;

                        MaterialSum += materialSub;
                        InstallSum += installSub;
                        TotalSum += materialSub + installSub;
                      });

                      Quote.Removal.Items.filter(
                        (removalItem) => removalItem.Tag_Id === t.Id
                      ).forEach((o) => {
                        const removalTotal = o.Price + o.LaborSubtotal;
                        MaterialSum += removalTotal; // Assuming removals are treated as material costs
                        TotalSum += removalTotal;
                      });

                      MaterialSum += totalAggregatedDeliveryCostWithTagId;
                      TotalSum += totalAggregatedDeliveryCostWithTagId;

                      const averageHourlyRate =
                        countItems > 0 ? sumHourlyRate / countItems : 0;

                      return (
                        <div key={j}>
                          <div>
                            <DeleteConfirmWindow
                              closeDel={() => {
                                setDeleteTagConfirmDisplay(false);
                              }}
                              onDeleteFunction={() => {
                                DeleteTagOnQuote(deleteTagId, params.quoteId);
                              }}
                              deleteOpen={deleteTagConfirmDisplay}
                            ></DeleteConfirmWindow>
                            <QuoteItemEstimator
                              isHeader={true}
                              header2={t.TagName}
                              ShowItemSelection={() => {
                                setSelectedTagId(t.Id);
                                setShowQuoteLineItem(true);
                                handleClick(t.Id);
                              }}
                              EditTag={() => {
                                setTagObj(t);
                                setTagWindowDisplay(true);
                              }}
                              DeleteTag={() => {
                                setDeleteTagId(t.Id);
                                setDeleteTagConfirmDisplay(true);
                              }}
                              bgColor={ColorPalette.White}
                            ></QuoteItemEstimator>

                            <StyledHRGreenBreakThick></StyledHRGreenBreakThick>

                            {selectedTagId === t.Id && showQuoteLineItem ? (
                              <QuoteLineItem
                                data-scroll-id={t.Id}
                                onClose={() => {
                                  setShowQuoteLineItem(false);
                                  setSelectedTagId(0);
                                  setAddOnSelectedItem(null);
                                }}
                                RefreshItemList={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                Tag_Id={t.Id}
                                addOnSelectedItem={addOnSelectedItem}
                              ></QuoteLineItem>
                            ) : null}

                            {primaryItems.map((o, i) => {
                              const materialSub =
                                displayStatus[o.Id] === true
                                  ? o.Price
                                  : o.SubtotalWithRelatedItems;
                              const installSub =
                                displayStatus[o.Id] === true
                                  ? o.LaborSubtotal
                                  : o.LaborSubtotalWithRelatedItems;

                              const originalIndex =
                                Quote.Material.Items.findIndex(
                                  (item) => item.Id === o.Id
                                );

                              // Get the total labor hours for this item's name
                              const totalHours =
                                totalHoursByProduct[
                                  `${o.Name}_${originalIndex}`
                                ] || 0;

                              timeSumByTag[o.Tag_Id] +=
                                totalHoursByProduct[
                                  `${o.Name}_${originalIndex}`
                                ];
                              const laborNames = laborNamesByProduct[
                                `${o.Name}_${originalIndex}`
                              ]
                                ? Array.from(
                                    laborNamesByProduct[
                                      `${o.Name}_${originalIndex}`
                                    ]
                                  ).join(", ")
                                : "";
                              // Extract the labor name(s) from the laborNames variable
                              const laborName = laborNames
                                ? laborNames.split(", ")[0]
                                : ""; // Adjust as needed if multiple names

                              // Find the corresponding labor item in Quote.Labor.Items
                              const laborItem = Quote.Labor.Items.find(
                                (item) => item.Name === laborName
                              );

                              // Get the price from the labor item, or default to 175 if not found
                              const hourlyRate = laborItem
                                ? laborItem.UnitPrice
                                : 154;
                              sumHourlyRate += hourlyRate;
                              countItems++;

                              return (
                                <QuoteItemEstimator
                                  isAccountant={true}
                                  noChange={o.NoChange}
                                  key={i}
                                  showRelatedItems={displayStatus[o.Id]}
                                  onToggleDisplay={() => toggleDisplay(o.Id)}
                                  RefreshComponentState={() => {
                                    GetProposalInformation(params.quoteId);
                                  }}
                                  inputheaderposition={headerInputShown}
                                  OutputValue={(value) => {
                                    UpdateQuoteItemQuantity(o.Id, value);
                                  }}
                                  showAddOnsSelection={() => {
                                    setShowQuoteLineItem(true);
                                    setSelectedTagId(t.Id);
                                    setAddOnSelectedItem(o);
                                  }}
                                  deleteItemFromEstimate={(itemId) => {
                                    if (itemId) {
                                      setDeleteItemId(itemId);
                                    } else {
                                      setDeleteItemId(o.Id);
                                    }
                                    setDeleteItemConfirmDisplay(true);
                                  }}
                                  quoteItemObj={o}
                                  parentid={o.Parent_Id}
                                  header1="Delete"
                                  header2={o.Name}
                                  subheader2notes={
                                    o.Notes == null ? "" : o.Notes + ""
                                  }
                                  subheader2={
                                    o.Description === null ? "" : o.Description
                                  }
                                  header3={
                                    Number(o?.Quant)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                    " " +
                                    o.DisplayUnit
                                  }
                                  header4={
                                    "$" +
                                    (o?.Quant !== 0
                                      ? (materialSub / o?.Quant).toFixed(4)
                                      : materialSub
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          ))
                                  }
                                  header5={
                                    "$" +
                                    materialSub
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header6={
                                    "$" +
                                    (o?.Quant !== 0
                                      ? (installSub / o?.Quant).toFixed(4)
                                      : installSub
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          ))
                                  }
                                  header7={
                                    "$" +
                                    installSub
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header8={laborNames ? ` ${laborNames}` : ""}
                                  header9={decimalHoursToTimeString(
                                    installSub / hourlyRate
                                  )}
                                  hourlyRate={hourlyRate}
                                  // header9={decimalHoursToTimeString(totalHours)}
                                  header10={
                                    "$" +
                                    (
                                      parseFloat(materialSub.toFixed(2)) +
                                      parseFloat(installSub.toFixed(2))
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  weightedRate={
                                    o?.Quant !== 0
                                      ? (
                                          materialSub / o?.Quant +
                                          installSub / o?.Quant
                                        )
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : "N/A"
                                  }
                                ></QuoteItemEstimator>
                              );
                            })}

                            {RemovalItemsWithTag.length > 0 ? (
                              <div key={`removal-tag`}>
                                <QuoteItemEstimator
                                  CategoryType={CategoryTypes.Removal}
                                  header1="Delete"
                                  header2="Removal"
                                  header3={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : `${
                                          parseFloat(
                                            RemovalItemsWithTag.reduce(
                                              (accumulator, item) =>
                                                accumulator + item.Quant,
                                              0
                                            )
                                          ).toFixed(2) || "0.00"
                                        } ${RemovalItemsWithTag[0].DisplayUnit}`
                                  }
                                  RemovalItems={RemovalItemsWithTag}
                                  showRelatedItems={removalDisplayStatus[t.Id]}
                                  RefreshComponentState={() => {
                                    GetProposalInformation(params.quoteId);
                                  }}
                                  deleteItemFromEstimate={(itemId) => {
                                    if (itemId) {
                                      setDeleteItemId(itemId);
                                    } else {
                                      console.log("no item passed");
                                    }
                                    setDeleteItemConfirmDisplay(true);
                                  }}
                                  onToggleDisplay={() =>
                                    toggleRemovalDisplay(t.Id)
                                  }
                                  header7={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : "$" +
                                        totalRemovalCost
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header9={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : decimalHoursToTimeString(
                                          totalRemovalTime
                                        )
                                  }
                                  header10={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : "$" +
                                        totalRemovalCost
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                ></QuoteItemEstimator>
                              </div>
                            ) : null}

                            {totalAggregatedDeliveryCostWithTagId > 0 ? (
                              <QuoteItemEstimator
                                key={`tagged-aggregated-delivery`}
                                quoteItemObj={{
                                  Price: totalAggregatedDeliveryCostWithTagId,
                                }}
                                showRelatedItems={displayStatus[t.Id]}
                                RefreshComponentState={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                deleteItemFromEstimate={async (item_id) => {
                                  setIsLoading(true);
                                  await DeleteQuoteTool(item_id);
                                  GetProposalInformation(params.quoteId);
                                  setIsLoading(false);
                                }}
                                CategoryType={"Delivery"}
                                onToggleDisplay={() => toggleDisplay(t.Id)}
                                DeliveryItems={matchedDeliveries}
                                header1="Delete"
                                header2="Deliveries"
                                // header3=""
                                header3={
                                  matchedDeliveries.reduce(
                                    (accumulator, tool) =>
                                      accumulator + tool.Quant,
                                    0
                                  ) || 0
                                }
                                header10={
                                  displayStatus[t.Id] === true
                                    ? ""
                                    : "$" +
                                      totalAggregatedDeliveryCostWithTagId
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              ></QuoteItemEstimator>
                            ) : null}

                            {primaryItems.length > 0 ||
                            RemovalItemsWithTag.length > 0 ? (
                              <QuoteItemEstimator
                                isHeader={true}
                                header2="Subtotal"
                                header5={
                                  "$" +
                                  MaterialSum.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                header7={
                                  "$" +
                                  InstallSum.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                header9={
                                  InstallSum === 0 || averageHourlyRate === 0
                                    ? decimalHoursToTimeString(totalRemovalTime)
                                    : decimalHoursToTimeString(
                                        InstallSum / averageHourlyRate +
                                          totalRemovalTime
                                      )
                                }
                                header10={
                                  "$" +
                                  TotalSum.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                bgColor={ColorPalette.White}
                              ></QuoteItemEstimator>
                            ) : null}
                          </div>
                        </div>
                      );
                      Object.keys(timeSumByTag).forEach((tagId) => {
                        timeSumByTag[tagId] = decimalHoursToTimeString(
                          timeSumByTag[tagId]
                        );
                      });
                    })}

                    {generalMaterialsHeader === "flex" ? <br></br> : null}

                    {generalMaterialsHeader === "flex" ? (
                      <QuoteItemEstimator
                        isHeader={true}
                        header2="General Materials"
                        ShowItemSelection={() => {
                          setShowQuoteLineItem(true);
                          setSelectedTagId(0);
                        }}
                        // header3="Quantity"
                        // header4="Material Rate"
                        // header5="Material Subtotal"
                        // header6="Install Rate"
                        // header7="Install Subtotal"
                        // header8="Line Subtotal"
                        bgColor={ColorPalette.White}
                      ></QuoteItemEstimator>
                    ) : null}

                    <StyledHRGreenBreakThick
                      display={generalMaterialsHeader}
                    ></StyledHRGreenBreakThick>
                    {selectedTagId === 0 && showQuoteLineItem ? (
                      <QuoteLineItem
                        onClose={() => {
                          setShowQuoteLineItem(false);
                          setSelectedTagId(0);
                          setAddOnSelectedItem(null);
                        }}
                        RefreshItemList={() => {
                          GetProposalInformation(params.quoteId);
                        }}
                        Tag_Id={0}
                        addOnSelectedItem={addOnSelectedItem}
                      ></QuoteLineItem>
                    ) : null}
                    {Quote?.Material?.Items.map((o, i) => {
                      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
                        const materialSub =
                          displayStatus[o.Id] === true
                            ? o.Price
                            : o.SubtotalWithRelatedItems;
                        const installSub =
                          displayStatus[o.Id] === true
                            ? o.LaborSubtotal
                            : o.LaborSubtotalWithRelatedItems;

                        const matchedDeliveries = [];
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (o.Id === tool.Item_Id) {
                            matchedDeliveries.push({
                              ...tool,
                              Tag_Id: o.Tag_Id,
                            });
                          }
                        });

                        let totalDeliveryCost = 0;
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (o.Id === tool.Item_Id) {
                            totalDeliveryCost += tool.Price;
                          }
                        });

                        // Get the index of the item in the original array
                        const originalIndex = Quote.Material.Items.findIndex(
                          (item) => item.Id === o.Id
                        );

                        // Get the total labor hours for this item's name
                        const totalHours =
                          totalHoursByProduct[`${o.Name}_${originalIndex}`] ||
                          0;

                        const laborNames = laborNamesByProduct[
                          `${o.Name}_${originalIndex}`
                        ]
                          ? Array.from(
                              laborNamesByProduct[`${o.Name}_${originalIndex}`]
                            ).join(", ")
                          : "";

                        const laborName = laborNames
                          ? laborNames.split(", ")[0]
                          : ""; // Adjust as needed if multiple names

                        // Find the corresponding labor item in Quote.Labor.Items
                        const laborItem = Quote.Labor.Items.find(
                          (item) => item.Name === laborName
                        );

                        // Get the price from the labor item, or default to 175 if not found
                        const hourlyRate = laborItem
                          ? laborItem.UnitPrice
                          : 154;

                        return (
                          <QuoteItemEstimator
                            isAccountant={true}
                            showRelatedItems={displayStatus[o.Id]}
                            onToggleDisplay={() => toggleDisplay(o.Id)}
                            RefreshComponentState={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            key={i}
                            quoteItemObj={o}
                            inputheaderposition={headerInputShown}
                            OutputValue={(value) => {
                              UpdateQuoteItemQuantity(o.Id, value);
                            }}
                            showAddOnsSelection={() => {
                              setShowQuoteLineItem(true);
                              setSelectedTagId(0);
                              setAddOnSelectedItem(o);
                            }}
                            deleteItemFromEstimate={(itemId) => {
                              if (itemId) {
                                setDeleteItemId(itemId);
                              } else {
                                setDeleteItemId(o.Id);
                              }
                              setDeleteItemConfirmDisplay(true);
                            }}
                            noChange={o.NoChange}
                            parentid={o.Parent_Id}
                            header1="Delete"
                            header2={o.Name}
                            subheader2={
                              o.Description === null ? "" : o.Description
                            }
                            subheader2notes={
                              o.Notes == null ? "" : o.Notes + ""
                            }
                            header3={
                              o?.Quant?.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              " " +
                              o.DisplayUnit
                            }
                            header4={
                              "$" +
                              (o?.Quant !== 0
                                ? (materialSub / o?.Quant).toFixed(4)
                                : materialSub
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                            }
                            header5={
                              "$" +
                              materialSub
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            header6={
                              "$" +
                              (o?.Quant !== 0
                                ? (installSub / o?.Quant).toFixed(4)
                                : installSub
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                            }
                            header7={
                              "$" +
                              installSub
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            header8={laborNames ? ` ${laborNames}` : ""}
                            header9={decimalHoursToTimeString(
                              installSub / hourlyRate
                            )}
                            hourlyRate={hourlyRate}
                            weightedRate={
                              o?.Quant !== 0
                                ? (
                                    materialSub / o?.Quant +
                                    installSub / o?.Quant
                                  )
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "N/A"
                            }
                            header10={
                              "$" +
                              (
                                parseFloat(materialSub.toFixed(2)) +
                                parseFloat(installSub.toFixed(2))
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                          ></QuoteItemEstimator>
                        );
                      }
                      return null;
                    }).concat(
                      <div key={`removal-no-tag`}>
                        <QuoteItemEstimator
                          CategoryType={CategoryTypes.Removal}
                          deleteItemFromEstimate={(itemId) => {
                            if (itemId) {
                              setDeleteItemId(itemId);
                            } else {
                              setDeleteItemId(o.Id);
                            }
                            setDeleteItemConfirmDisplay(true);
                          }}
                          RefreshComponentState={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          header1="Delete"
                          header2="Removal"
                          header3={
                            removalDisplayStatus === true
                              ? ""
                              : `${
                                  parseFloat(
                                    RemovalItemsNoTag.reduce(
                                      (accumulator, item) =>
                                        accumulator + item.Quant,
                                      0
                                    )
                                  ).toFixed(2) || "0.00"
                                } ${
                                  RemovalItemsNoTag.length > 0
                                    ? RemovalItemsNoTag[0].DisplayUnit
                                    : ""
                                }`
                          }
                          RemovalItems={RemovalItemsNoTag}
                          showRelatedItems={removalDisplayStatus}
                          onToggleDisplay={() => {
                            setRemovalDisplayStatus(!removalDisplayStatus);
                          }}
                          // header3={totalRemovalQuantity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          // header6={removalDisplayStatus === false ?  "$" + (totalRemovalCost / totalRemovalQuantity).toFixed(4): ''}
                          header7={
                            removalDisplayStatus === false
                              ? "$" +
                                totalRemovalCost
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }
                          header9={
                            removalDisplayStatus === false
                              ? decimalHoursToTimeString(totalRemovalTime)
                              : ""
                          }
                          header10={
                            removalDisplayStatus === false
                              ? "$" +
                                totalRemovalCost
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }
                        ></QuoteItemEstimator>
                      </div>,
                      totalAggregatedDeliveryCost > 0 ? (
                        <QuoteItemEstimator
                          key={`total-aggregated-delivery`}
                          quoteItemObj={{ Price: totalAggregatedDeliveryCost }}
                          DeliveryItems={DeliveryItemsNoTag}
                          CategoryType={"Delivery"}
                          RefreshComponentState={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          deleteItemFromEstimate={async (item_id) => {
                            setIsLoading(true);
                            await DeleteQuoteTool(item_id);
                            GetProposalInformation(params.quoteId);
                            setIsLoading(false);
                          }}
                          showRelatedItems={deliveryDisplayStatus}
                          onToggleDisplay={() => {
                            setDeliveryDisplayStatus(!deliveryDisplayStatus);
                          }}
                          header1="Delete"
                          header2="Deliveries"
                          header3={
                            DeliveryItemsNoTag.reduce(
                              (accumulator, tool) => accumulator + tool.Quant,
                              0
                            ) || 0
                          }
                          header4=""
                          header10={
                            deliveryDisplayStatus === false
                              ? "$" +
                                totalAggregatedDeliveryCost
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }
                        ></QuoteItemEstimator>
                      ) : null,

                      generalMaterialsHeader === "flex" ? (
                        <QuoteItemEstimator
                          isHeader={true}
                          key={"untagged_subtotals"}
                          header2={"Subtotal"}
                          bgColor={"White"}
                          header5={`$${finalUntaggedMaterialSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          header7={`$${finalUntaggedInstallSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          header9={totalTimeString}
                          header10={`$${finalUntaggedTotalSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                      ) : null
                    )}

                    {removalHeader === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}

                    {toolsmachinesHeader === "flex" ? (
                      <div>
                        <QuoteItemEstimator
                          isHeader={true}
                          ShowItemSelection={() => {
                            setSelectedTagId(0);
                            setShowToolLineItem(true);
                          }}
                          header2="Machines, Disposals, & Access"
                          // header3="Quantity"
                          header4=""
                          header5=""
                          // header6="Rental Rate"
                          header7="Move Cost Per Machine"
                          header8="Line Subtotal"
                          bgColor={ColorPalette.White}
                        ></QuoteItemEstimator>
                        <FlexRowCont100PercentSpaceBetween>
                          <FlexColumnDiv>
                            <TinyTextItalics>Length</TinyTextItalics>
                            <InputField
                              defaultValue={Quote?.AccessArea?.Length}
                              onChange={(e) => {
                                setProposal((prevQuote) => ({
                                  ...prevQuote,
                                  AccessArea: {
                                    ...prevQuote.AccessArea,
                                    Length: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </FlexColumnDiv>
                          <FlexColumnDiv>
                            <TinyTextItalics>Width</TinyTextItalics>
                            <InputField
                              defaultValue={Quote?.AccessArea?.Width}
                              onChange={(e) => {
                                setProposal((prevQuote) => ({
                                  ...prevQuote,
                                  AccessArea: {
                                    ...prevQuote.AccessArea,
                                    Width: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </FlexColumnDiv>
                          <FlexColumnDiv>
                            <TinyTextItalics>Elevation</TinyTextItalics>
                            <InputField
                              defaultValue={Quote?.AccessArea?.Elevation}
                              onChange={(e) => {
                                setProposal((prevQuote) => ({
                                  ...prevQuote,
                                  AccessArea: {
                                    ...prevQuote.AccessArea,
                                    Elevation: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </FlexColumnDiv>
                          <FlexColumnDiv>
                            <TinyTextItalics>Obstruction</TinyTextItalics>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="obstruct"
                                  value="true"
                                  checked={Quote?.AccessArea?.Obstruct === true}
                                  onChange={() => {
                                    setProposal((prevQuote) => ({
                                      ...prevQuote,
                                      AccessArea: {
                                        ...prevQuote.AccessArea,
                                        Obstruct: true,
                                      },
                                    }));
                                  }}
                                />
                                True
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="obstruct"
                                  value="false"
                                  checked={
                                    Quote?.AccessArea?.Obstruct === false
                                  }
                                  onChange={() => {
                                    setProposal((prevQuote) => ({
                                      ...prevQuote,
                                      AccessArea: {
                                        ...prevQuote.AccessArea,
                                        Obstruct: false,
                                      },
                                    }));
                                  }}
                                />
                                False
                              </label>
                            </div>
                          </FlexColumnDiv>
                        </FlexRowCont100PercentSpaceBetween>
                      </div>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={toolsmachinesHeader}
                    ></StyledHRGreenBreakThick>

                    {selectedTagId === 0 && showToolLineItem ? (
                      <QuoteLineItem
                        onClose={() => {
                          setShowToolLineItem(false);
                          setSelectedTagId(0);
                          setAddOnSelectedItem(null);
                        }}
                        RefreshItemList={() => {
                          GetProposalInformation(params.quoteId);
                        }}
                        Tag_Id={0}
                        addOnSelectedItem={addOnSelectedItem}
                      ></QuoteLineItem>
                    ) : null}

                    {Quote.Tool.Tools.sort((a, b) =>
                      a.ToolName.localeCompare(b.ToolName)
                    ).map((o, i) => {
                      if (!o?.ToolName.includes("Wheelbarrow")) {
                        if (o.RentalRate > 0 && o.BasePrice > 0) {
                          toolsHeader7Subtotal += o.BasePrice;
                        }
                        toolsHeader8Subtotal += o.Price;
                      }

                      return (
                        <QuoteItemEstimator
                          key={i}
                          CategoryType={"Tool"}
                          RefreshComponentState={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          header1="Delete"
                          header2={o.ToolName}
                          subheader2={o.Notes !== null ? o.Notes : ""}
                          header4={
                            o.RentalRate > 0
                              ? `$${o.RentalRate.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}`
                              : null
                          }
                          header6={
                            o.BasePrice > 0
                              ? `$${o.BasePrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}`
                              : null
                          }
                          deleteItemFromEstimate={async (itemId) => {
                            if (itemId) {
                              setIsLoading(true);
                              setDeleteItemId(itemId);
                              const data = await DeleteQuoteTool(itemId);
                              if (data === null) {
                                GetProposalInformation(params.quoteId);
                                setIsLoading(false);
                              }
                            } else {
                              setDeleteItemId(o.Id);
                              const data = await DeleteQuoteTool(o.Id);
                              if (data === null) {
                                GetProposalInformation(params.quoteId);
                                setIsLoading(false);
                              }
                            }
                          }}
                          quoteItemObj={o}
                          header9={o.RentalLength > 0 ? o.RentalLength : null}
                          itemLink={o.VideoUrl}
                          header3={
                            typeof o?.Quant === "number" && !isNaN(o.Quant)
                              ? o.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : ""
                          }
                          header8={
                            o.RentalRate > 0 && o.BasePrice > 0
                              ? "$" +
                                o.BasePrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : ""
                          }
                          header10={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                        ></QuoteItemEstimator>
                      );
                    })}

                    {Quote.AccessArea.Items.map((o, i) => (
                      <div>
                        <QuoteItemEstimator
                          key={i}
                          showRelatedItems={showRelated}
                          onClick={() => {
                            setShowRelated(!showRelated);
                          }}
                          RefreshComponentState={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          deleteItemFromEstimate={(itemId) => {
                            if (itemId) {
                              setDeleteItemId(itemId);
                            } else {
                              setDeleteItemId(o.Id);
                            }
                            setDeleteItemConfirmDisplay(true);
                          }}
                          quoteItemObj={o}
                          CategoryType={CategoryTypes.Access}
                          header1="Delete"
                          header2={o.Name}
                          subheader2={o.Notes}
                          header3={
                            o?.Quant.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            " " +
                            o.DisplayUnit
                          }
                          header5=""
                          header4=""
                          header6={
                            "$" +
                            o.UnitPrice.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            "" +
                            o.DisplayUnit
                          }
                          header7={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          header10={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                        ></QuoteItemEstimator>
                      </div>
                    ))}

                    {(toolsHeader7Subtotal > 0 || toolsHeader8Subtotal > 0) && (
                      <QuoteItemEstimator
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        header7={
                          "$" +
                          (toolsHeader7Subtotal + accessTotal)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        header10={
                          "$" +
                          (toolsHeader8Subtotal + accessTotal)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItemEstimator>
                    )}
                    {toolsmachinesHeader === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}
                    <FlexRowContFullWidth
                      id="exclude-from-pdf"
                      onClick={() => {
                        DisplayLaborBreakdown(laborDetailToggle);
                      }}
                    ></FlexRowContFullWidth>
                  </AutoSizeOverflowY>
                </FlexColumnFullWidth>
              </ContentContQuote>
            </FlexRowContAutox100PercentMobileColumn>
          </FlexColumn81vwLightGrey>

          <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop>

          {isLoading === true ? (
            <Backdrop style={{ zIndex: 1000 }} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : null}
          <DeleteConfirmWindow
            closeDel={() => {
              setDeleteItemConfirmDisplay(false);
            }}
            onDeleteFunction={async () => {
              setIsLoading(true);
              const data = await DeleteQuoteItemByEstimator(deleteItemId);
              if (data === null) {
                GetProposalInformation(params.quoteId);
                setIsLoading(false);
              }
            }}
            deleteOpen={deleteItemConfirmDisplay}
          ></DeleteConfirmWindow>
        </Cont>
      );
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

ViewProposalAccountantView.defaultProps = {
  ProfileName: "Default Name",
};

export default ViewProposalAccountantView;
