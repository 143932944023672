// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import {
//   AddQuoteItem,
//   GetQuoteById,
//   GetQuoteItem,
//   GetQuoteTag,
//   UpdateQuoteItem,
// } from "../../../API_Functions/CaseQuote_Functions";
// import { CategoryTypes } from "../../../StylesheetComps/CategoryTypes";
// import { ColorPalette } from "../../../StylesheetComps/Colors";
// import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
// import {
//   AbsoluteCalculatorCont,
//   FlexDiv,
//   StickyCont400x400px,
// } from "../../../StylesheetComps/Cont";
// import { Div300pxMargin30px, FlexCenterDiv100PercentCenter } from "../../../StylesheetComps/Div";
// import {
//   DatalistOption,
//   TextArea72Percent,
//   TextInput72Percent,
//   TextSelect72Percent,
// } from "../../../StylesheetComps/LandscapeForm";
// import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
// import { Cont100PercentWidth, Icon30px20pxPointer } from "../../../StylesheetComps/Quote";
// import {
//   LeagueSpartanHeaderSmall,
//   RegularTextBoldRed,
//   StyledHRGreyBreak,
//   TinyTextItalics,
// } from "../../../StylesheetComps/Titles";
// import CalculatorWindow from "../Calculators";
// import TagWindow from "../Tag";
// import { GetStoreProducts } from "../../../API_Functions/CatProduct_Functions";

// const QuoteItemWindow = ({
//   open,
//   onClose,
//   onCloseNoAdd,
//   onPreview,
//   productobj,
//   itemId,
//   quoteid,
//   quote,
//   RefreshItemList,
//   RefreshUpdatedItem,
//   siteMapper,
//   squareFeetSM,
//   perimeterSM,
//   iconCount
// }) => {
//   const [squareFeet, setSquareFeet] = useState(0);
//   const [depth, setDepth] = useState(0);
//   const [cubicFeetTotal, setCubicFeetTotal] = useState(0);
//   const [taglist, setTaglist] = useState([]);
//   const [calculatorsVisible, setCalculatorsVisible] = useState(false);

//   //Removals
//   const [removalArea, setRemovalArea] = useState(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
//   const [removalDepth, setRemovalDepth] = useState(0);

//   const [managerNotes, setManagerNotes] = useState("");
//   const [itemObj, setItemObj] = useState({});
//   const [addTagWindowVisible, setAddTagWindowVisible] = useState(false);
//   const [tagObj, setTagObj] = useState({});

//   const [inputValue, setInputValue] = useState(0);
//   const [isUserInput, setIsUserInput] = useState(false);

//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     setRemovalArea(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
//   }, [siteMapper, squareFeetSM]);

//   const params = useParams();

//   const GetItem = async (itemid) => {
//     try {
//       const data = await GetQuoteItem(itemid);
//       if (data && data.item) {
//         setItemObj(data.item);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     setCubicFeetTotal(squareFeet * (depth / 12));
//   }, [depth, squareFeet]);

//   const GetQuoteTaglist = async (quote_id) => {
//     if (quote_id !== 0 && quote_id !== undefined) {
//       try {
//         const data = await GetQuoteById(quote_id);
//         if (data && data.quote) {
//           setTaglist(data.quote.Tags);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   };
  
//   useEffect(() => {
//     GetQuoteTaglist(params.quoteId);
//     setTaglist(quote?.Tags);
//   }, [quote, productobj]);

//   const ResetCalculator = async () => {
//     setCubicFeetTotal(0);
//     setSquareFeet(0);
//     setDepth(0);
//   };

//   const AddNewItemToQuote = async (itemobj) => {
//     onClose(true);
//     try {
//       const data = await AddQuoteItem(itemobj);
//       if (data && data.item) {
//         RefreshItemList(data.item);
//         if (siteMapper === true) {
//           ResetCalculator();
//         }
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
  
  
//   const UpdateItemOnQuote = async (itemobj) => {
//     try {
//       const data = await UpdateQuoteItem(itemobj);
//       if (data && data.item) {
//         RefreshUpdatedItem(data.item);
//         ResetCalculator();
//         onClose(true);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
  

//   // const SaveItem = async (itemobj) => {
//   //   if (itemobj) {
//   //     if (itemobj.CateType === CategoryTypes.Removal) {
//   //       itemObj.Quant = removalArea * (removalDepth / 12);
//   //     }
//   //     if (itemobj.Id === 0) {
//   //       if(siteMapper === true && itemobj.CateType === CategoryTypes.Material) {
//   //         setInputValue(squareFeetSM.toFixed(2));
//   //         var value = (squareFeetSM.toFixed(2))
//   //         itemObj.Quant = value;
//   //       }
//   //       AddNewItemToQuote(itemObj);
//   //     } else {
//   //       UpdateItemOnQuote(itemObj);
//   //     }
//   //   }
//   // };

  // const SaveItem = async (itemobj) => {
  //   setIsLoading(true); // Start loading
  //   if (itemobj) {
  //     if (itemobj.CateType === CategoryTypes.Removal) {
  //         itemObj.Quant = removalArea * (removalDepth / 12);
  //     }
  //     if (itemobj.Id === 0) {
  //       if(siteMapper === true && itemobj.CateType === CategoryTypes.Material && !isUserInput) {
  //         setInputValue(squareFeetSM.toFixed(2));
  //         var value = (squareFeetSM.toFixed(2))
  //         itemObj.Quant = value;
  //       }
  //       AddNewItemToQuote(itemObj);
  //     } else {
  //       UpdateItemOnQuote(itemObj);
  //     }
  //   }
  //   setInputValue(0);
  //   setIsLoading(false); // Stop loading once done
  //   setIsUserInput(false); // Reset the isUserInput state for the next input
  // };

//   useEffect(() => {
//     if (itemId === 0) {
//       itemObj.Name = productobj.Name;
//       itemObj.CateType = productobj.CateType;
//       itemObj.Product_Id = productobj.Id;
//       itemObj.UnitPrice = productobj.UnitPrice;
//       itemObj.Unit = productobj.Unit;
//       itemObj.Quote_Id = params.quoteId;
//     }
//   }, [itemObj]); // eslint-disable-line react-hooks/exhaustive-deps

//   useEffect(() => {
//     if (productobj !== undefined) {
//       setManagerNotes(productobj.Notes);
//       GetItem(itemId);
//     }
//   }, [productobj]); // eslint-disable-line react-hooks/exhaustive-deps

//   if (!open) return null;
//   if (itemId === 0) {
//     return (
//       <>
//         {" "}
//         <Backdrop style={{ zIndex: 2 }} open={isLoading} onClick={()=>{setIsLoading(false)}}>
//         <CircularProgress color="inherit" />
//       </Backdrop>
//         <FixedOverlay1000vh>
//           <CalculatorWindow isVisible={calculatorsVisible} onClose={()=>{setCalculatorsVisible(false)}} squareFeetProp={squareFeetSM}></CalculatorWindow>
//           <StickyCont400x400px height={siteMapper === true ? '95vh' : ''}>
//             <Div300pxMargin30px>
//             <FlexCenterDiv100PercentCenter display={siteMapper == true ? 'flex' : 'none'}>
//               <LeagueSpartanHeaderSmall></LeagueSpartanHeaderSmall>
//               <TinyTextItalics><b> Sq Ft: </b>{squareFeetSM?.toFixed(2)} sqft.</TinyTextItalics>
//               <LeagueSpartanHeaderSmall></LeagueSpartanHeaderSmall>
//               <TinyTextItalics> <b> Perimeter: </b>{perimeterSM?.toFixed(2)} ft.</TinyTextItalics>
//               </FlexCenterDiv100PercentCenter>
//               <FlexCenterDiv100PercentCenter>
//               <LeagueSpartanHeaderSmall></LeagueSpartanHeaderSmall>
//               <TinyTextItalics> <b> Icons Plotted: </b>{iconCount}</TinyTextItalics>
//               </FlexCenterDiv100PercentCenter>
//               <FlexDiv>
//               <LeagueSpartanHeaderSmall>Name</LeagueSpartanHeaderSmall>
//               <Icon30px20pxPointer squareFeet={squareFeetSM} src="/calculator_icon.svg" onClick={()=>{setCalculatorsVisible(true)}}></Icon30px20pxPointer>
//               </FlexDiv>
//               <TextInput72Percent
//                 defaultValue={productobj.Name}
//                 onChange={(e) => {
//                   itemObj.Name = e.target.value;
//                 }}
//               ></TextInput72Percent>

//               <RegularTextBoldRed>{managerNotes}</RegularTextBoldRed>

//               {productobj.CateType === CategoryTypes.Material ||
//               productobj.CateType === CategoryTypes.Open ||
//               productobj.CateType === CategoryTypes.Addon ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>
//                     Quantity ({productobj?.Unit}) of Product
//                   </LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                   inputMode='numeric'
//                   value={inputValue}
//                   key={iconCount > 0 ? iconCount : siteMapper == true ? squareFeetSM : ''}
//                   onChange={(e) => {
//                     setInputValue(e.target.value);
//                     itemObj.Quant = e.target.value;
//                     setIsUserInput(true); // User has entered input
//                   }}                
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {productobj.CateType === CategoryTypes.Removal ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>
//                     Area (Square Feet)*
//                   </LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     value={removalArea}
//                     key={siteMapper === true ? squareFeetSM : ''}
//                     onChange={(e) => {
//                       setRemovalArea(e.target.value);
//                     }}
              
//                   ></TextInput72Percent>
//                   <LeagueSpartanHeaderSmall>Depth (Inches)*</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setRemovalDepth(e.target.value);
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {productobj.CateType === CategoryTypes.Labor ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>Hours</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       itemObj.Quant = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {productobj.CateType === CategoryTypes.Open ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>Unit</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     defaultValue={productobj.Unit}
//                     onChange={(e) => {
//                       itemObj.Unit = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                   <LeagueSpartanHeaderSmall>Product Price</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     defaultValue={productobj.UnitPrice}
//                     onChange={(e) => {
//                       itemObj.UnitPrice = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {productobj.CateType === CategoryTypes.Material ||
//               productobj.CateType === CategoryTypes.Open || productobj.CateType === CategoryTypes.Removal ? (
//                 <div>
//                 <LeagueSpartanHeaderSmall>Tag</LeagueSpartanHeaderSmall>
//                 <FlexDiv>
//                   <TextSelect72Percent
//                     defaultValue={productobj.Tag_Id}
//                     onChange={(e) => {
//                       itemObj.Tag_Id = e.target.value;
//                     }}
//                   >
//                     <DatalistOption value={0}>General Materials</DatalistOption>
//                     {taglist?.map((o, i) => (
//                       <DatalistOption value={o.Id} key={i}>
//                         {o.TagName}
//                       </DatalistOption>
//                     ))}
//                   </TextSelect72Percent>
//                   <Icon30px20pxPointer onClick={async()=>{
//                     var tag = await GetQuoteTag(0);
//                     setTagObj(tag);
//                     setAddTagWindowVisible(true)
//                   }} src='/Green Button.svg'></Icon30px20pxPointer>
//                 </FlexDiv>
//                 </div>
//               ) : null}
//               <LeagueSpartanHeaderSmall>Notes</LeagueSpartanHeaderSmall>
//               <TextArea72Percent
//                 onChange={(e) => {
//                   itemObj.Notes = e.target.value;
//                 }}
//               ></TextArea72Percent>

//               <br></br>
//               <RegularResponsiveButton
//                 onClick={() => {
//                   SaveItem(itemObj);
//                 }}
//               >
//                 Add
//               </RegularResponsiveButton>

//               <RegularResponsiveButton
//                 bgcolor={ColorPalette.PrimaryRed}
//                 onClick={() => {
//                   if(siteMapper == true) {
//                     onCloseNoAdd()
//                   } else {
//                     onClose(false);
//                   }
//                   ResetCalculator();
//                 }}
//               >
//                 Cancel
//               </RegularResponsiveButton>
//             </Div300pxMargin30px>
//           </StickyCont400x400px>
//           {productobj.CateType === CategoryTypes.Material ? (
//             <div>
//               <AbsoluteCalculatorCont>
//                 <Div300pxMargin30px>
//                   <LeagueSpartanHeaderSmall>
//                     Square Feet to Cubic Feet
//                   </LeagueSpartanHeaderSmall>

//                   <StyledHRGreyBreak></StyledHRGreyBreak>

//                   <LeagueSpartanHeaderSmall>Square Feet</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setSquareFeet(e.target.value);
//                     }}
//                   ></TextInput72Percent>

//                   <LeagueSpartanHeaderSmall>Depth (Inches)</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setDepth(e.target.value);
//                     }}
//                   ></TextInput72Percent>
//                   <Cont100PercentWidth>
//                     <LeagueSpartanHeaderSmall>
//                       Cubic Feet: {cubicFeetTotal.toFixed(2)}
//                     </LeagueSpartanHeaderSmall>
//                   </Cont100PercentWidth>
//                 </Div300pxMargin30px>
//               </AbsoluteCalculatorCont>
//             </div>
//           ) : null}
//           <TagWindow tagObj={tagObj}
//                 quoteid={params.quoteId}
//                 open={addTagWindowVisible}
//                 RefreshTaglist={() => {
//                   GetQuoteTaglist(params.quoteId);
//                 }}
//                 onClose={() => {
//                   setAddTagWindowVisible(false);
//                 }}></TagWindow>
//         </FixedOverlay1000vh>{" "}
//       </>
//     );
//   } else if (itemObj.Id === itemId) {
//     ///////////////////////////////////////////////////////////////////////////////////
//     ///////////////////////////////////////////////////////////////////////////////////
//     /////////////////////////////Edit Item/////////////////////////////////////////////
//     ///////////////////////////////////////////////////////////////////////////////////
//     ///////////////////////////////////////////////////////////////////////////////////
//     ///////////////////////////////////////////////////////////////////////////////////
//     return (
//       <>
//         {" "}
//         <FixedOverlay1000vh>
//           <StickyCont400x400px>
//             <Div300pxMargin30px>
//               <LeagueSpartanHeaderSmall>Name</LeagueSpartanHeaderSmall>
//               <TextInput72Percent
//                 defaultValue={itemObj.Name}
//                 key={itemObj.Name}
//                 onChange={(e) => {
//                   itemObj.Name = e.target.value;
//                 }}
//               ></TextInput72Percent>

//               {itemObj.CateType === CategoryTypes.Material ||
//               itemObj.CateType === CategoryTypes.Open ||
//               itemObj.CateType === CategoryTypes.Addon ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>
//                     Quantity ({itemObj.Unit}) of Product
//                   </LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     key={itemObj.Quant}
//                     defaultValue={itemObj.Quant}
//                     onChange={(e) => {
//                       itemObj.Quant = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {itemObj.CateType === CategoryTypes.Removal ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>
//                     Area (Square Feet)*
//                   </LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setRemovalArea(e.target.value);
//                     }}
//                   ></TextInput72Percent>
//                   <LeagueSpartanHeaderSmall>Depth (Inches)*</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setRemovalDepth(e.target.value);
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {itemObj.CateType === CategoryTypes.Labor ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>Hours</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     key={itemObj.Quant}
//                     defaultValue={itemObj.Quant}
//                     onChange={(e) => {
//                       itemObj.Quant = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {itemObj.CateType === CategoryTypes.Open ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>Unit</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     key={itemObj.Unit}
//                     defaultValue={itemObj.Unit}
//                     onChange={(e) => {
//                       itemObj.Unit = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                   <LeagueSpartanHeaderSmall>Product Price</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     key={itemObj.UnitPrice}
//                     defaultValue={itemObj.UnitPrice}
//                     onChange={(e) => {
//                       itemObj.UnitPrice = e.target.value;
//                     }}
//                   ></TextInput72Percent>
//                 </div>
//               ) : null}

//               {itemObj.CateType === CategoryTypes.Material ||
//               itemObj.CateType === CategoryTypes.Open ? (
//                 <div>
//                   <LeagueSpartanHeaderSmall>Tag</LeagueSpartanHeaderSmall>
//                   <TextSelect72Percent
//                     defaultValue={itemObj.Tag_Id}
//                     onChange={(e) => {
//                       itemObj.Tag_Id = e.target.value;
//                     }}
//                   >
//                     <DatalistOption value={0}>General Materials</DatalistOption>
//                     {taglist.map((o, i) => (
//                       <DatalistOption value={o.Id} key={i}>
//                         {o.TagName}
//                       </DatalistOption>
//                     ))}
//                   </TextSelect72Percent>
//                 </div>
//               ) : null}

//               <LeagueSpartanHeaderSmall>Notes</LeagueSpartanHeaderSmall>
//               <TextArea72Percent
//                 key={itemObj.Notes}
//                 defaultValue={itemObj.Notes}
//                 onChange={(e) => {
//                   itemObj.Notes = e.target.value;
//                 }}
//               ></TextArea72Percent>

//               <Cont100PercentWidth>
//                 <br></br>
//                 <RegularResponsiveButton
//                   onClick={() => {
//                     SaveItem(itemObj);
//                   }}
//                 >
//                   Update
//                 </RegularResponsiveButton>
//               </Cont100PercentWidth>
//               <RegularResponsiveButton
//                 bgcolor={ColorPalette.PrimaryRed}
//                 onClick={() => {
//                   onClose(false);
//                   ResetCalculator();
//                 }}
//               >
//                 Cancel
//               </RegularResponsiveButton>
//             </Div300pxMargin30px>
//           </StickyCont400x400px>
//           {itemObj.CateType === CategoryTypes.Material ? (
//             <div>
//               <AbsoluteCalculatorCont>
//                 <Div300pxMargin30px>
//                   <LeagueSpartanHeaderSmall>
//                     Square Feet to Cubic Feet
//                   </LeagueSpartanHeaderSmall>

//                   <StyledHRGreyBreak></StyledHRGreyBreak>

//                   <LeagueSpartanHeaderSmall>Square Feet</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setSquareFeet(e.target.value);
//                     }}
//                   ></TextInput72Percent>

//                   <LeagueSpartanHeaderSmall>Depth (Inches)</LeagueSpartanHeaderSmall>
//                   <TextInput72Percent
//                     onChange={(e) => {
//                       setDepth(e.target.value);
//                     }}
//                   ></TextInput72Percent>
//                   <Cont100PercentWidth>
//                     <LeagueSpartanHeaderSmall>
//                       Cubic Feet: {cubicFeetTotal.toFixed(2)}
//                     </LeagueSpartanHeaderSmall>
//                   </Cont100PercentWidth>
//                 </Div300pxMargin30px>
//               </AbsoluteCalculatorCont>
//             </div>
//           ) : null}
//         </FixedOverlay1000vh>{" "}
//       </>
//     );
//   } else {
//     return (
//       <div>
//         <Backdrop style={{ zIndex: 2 }} open>
//           <CircularProgress color="inherit" />
//         </Backdrop>
//       </div>
//     );
//   }
// };

// QuoteItemWindow.defaultProps = {
//   onChange: () => {},
//   onPreview: () => {},
//   onFormComplete: () => {},
//   RefreshItemList: () => {},
//   RefreshUpdatedItem: () => {},
//   defaultName: "",
//   unitForProduct: "",
//   GetQuoteInfoById:() => {},
//   onCloseNoAdd:() => {}
// };

// export default QuoteItemWindow;

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddQuoteItem,
  GetQuoteById,
  GetQuoteItem,
  GetQuoteTag,
  UpdateQuoteItem,
} from "../../../API_Functions/CaseQuote_Functions";
import { CategoryTypes } from "../../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  AbsoluteCalculatorCont,
  FlexDiv,
  StickyCont400x400px,
} from "../../../StylesheetComps/Cont";
import { Div300pxMargin30px, FlexCenterDiv100PercentCenter, FlexDivSpaceBetween } from "../../../StylesheetComps/Div";
import {
  DatalistOption,
  TextArea72Percent,
  TextInput72Percent,
  TextSelect72Percent,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth, Icon30px20pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  RegularTextBoldRed,
  StyledHRGreyBreak,
  TinyTextItalics,
} from "../../../StylesheetComps/Titles";
import CalculatorWindow from "../Calculators";
import TagWindow from "../Tag";
import IconButton from "../../IconButton";

// =========================
// Sub-Components for Fields
// =========================

function MaterialFields({
  productobj,
  inputValue,
  setInputValue,
  itemObj,
  iconCount,
  siteMapper,
  squareFeetSM,
  setIsUserInput
}) {
  return (
    <>
      <LeagueSpartanHeaderSmall>
        Quantity ({productobj?.Unit}) of Product
      </LeagueSpartanHeaderSmall>
      <TextInput72Percent
        inputMode="numeric"
        value={inputValue}
        key={iconCount > 0 ? iconCount : siteMapper === true ? squareFeetSM : ""}
        onChange={(e) => {
          setInputValue(e.target.value);
          itemObj.Quant = e.target.value;
          // setIsUserInput(true);
        }}
      />
          <br></br>
          <br></br>
    </>
  );
}

function RemovalFields({
  removalArea,
  setRemovalArea,
  removalDepth,
  setRemovalDepth,
  siteMapper,
  squareFeetSM,
}) {
  return (
    <>
      <LeagueSpartanHeaderSmall>Area (Square Feet)*</LeagueSpartanHeaderSmall>
      <TextInput72Percent
        value={removalArea}
        key={siteMapper === true ? squareFeetSM : ""}
        onChange={(e) => setRemovalArea(e.target.value)}
      />
          <br></br>
          <br></br>
      <LeagueSpartanHeaderSmall>Depth (Inches)*</LeagueSpartanHeaderSmall>
      <TextInput72Percent
        value={removalDepth}
        onChange={(e) => setRemovalDepth(e.target.value)}
      />
    <br></br>
    <br></br>
    </>
  );
}

function LaborFields({ itemObj }) {
  return (
    <>
      <LeagueSpartanHeaderSmall>Hours</LeagueSpartanHeaderSmall>
      <TextInput72Percent
        defaultValue={itemObj.Quant || ""}
        onChange={(e) => {
          itemObj.Quant = e.target.value;
        }}
      />
    <br></br>
    <br></br>
    </>
  );
}

function OpenFields({ productobj, itemObj }) {
  return (
    <>
      <LeagueSpartanHeaderSmall>Unit</LeagueSpartanHeaderSmall>
      <TextInput72Percent
        defaultValue={productobj.Unit}
        onChange={(e) => {
          itemObj.Unit = e.target.value;
        }}
      />
          <br></br>
          <br></br>
      <LeagueSpartanHeaderSmall>Product Price</LeagueSpartanHeaderSmall>
      <TextInput72Percent
        defaultValue={productobj.UnitPrice}
        onChange={(e) => {
          itemObj.UnitPrice = e.target.value;
        }}
      />
          <br></br>
          <br></br>
    </>
  );
}

function TagField({
  productobj,
  itemObj,
  taglist,
  setAddTagWindowVisible,
  setTagObj,
}) {
  return (
    <>
      <LeagueSpartanHeaderSmall>Tag</LeagueSpartanHeaderSmall>
      <FlexDiv>
        <TextSelect72Percent
          defaultValue={productobj.Tag_Id}
          onChange={(e) => {
            itemObj.Tag_Id = e.target.value;
          }}
        >
          <DatalistOption value={0}>General Materials</DatalistOption>
          {taglist?.map((o, i) => (
            <DatalistOption value={o.Id} key={i}>
              {o.TagName}
            </DatalistOption>
          ))}
        </TextSelect72Percent>
        <Icon30px20pxPointer
          onClick={async () => {
            const tag = await GetQuoteTag(0);
            setTagObj(tag);
            setAddTagWindowVisible(true);
          }}
          src="/Green Button.svg"
        ></Icon30px20pxPointer>
      </FlexDiv>
      <br></br>
      <br></br>
    </>
  );
}

// =============
// Main Component
// =============

const QuoteItemWindow = ({
  open,
  onClose,
  onCloseNoAdd,
  productobj,
  itemId,
  quoteid,
  quote,
  RefreshItemList,
  RefreshUpdatedItem,
  siteMapper,
  squareFeetSM,
  perimeterSM,
  iconCount
}) => {
  const [squareFeet, setSquareFeet] = useState(0);
  const [depth, setDepth] = useState(0);
  const [cubicFeetTotal, setCubicFeetTotal] = useState(0);
  const [taglist, setTaglist] = useState([]);
  const [calculatorsVisible, setCalculatorsVisible] = useState(false);

  // Removals
  const [removalArea, setRemovalArea] = useState(
    siteMapper === true ? squareFeetSM.toFixed(2) : 0
  );
  const [removalDepth, setRemovalDepth] = useState(0);

  const [managerNotes, setManagerNotes] = useState("");
  const [itemObj, setItemObj] = useState({}); // The item state (for new or editing)
  const [addTagWindowVisible, setAddTagWindowVisible] = useState(false);
  const [tagObj, setTagObj] = useState({});

  const [inputValue, setInputValue] = useState(0);
  // const [isUserInput, setIsUserInput] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    setRemovalArea(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
  }, [siteMapper, squareFeetSM]);

  useEffect(() => {
    setCubicFeetTotal(squareFeet * (depth / 12));
  }, [depth, squareFeet]);

  const GetItem = async (itemid) => {
    try {
      const data = await GetQuoteItem(itemid);
      if (data && data.item) {
        setItemObj(data.item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Grab tags from the quote
  const GetQuoteTaglist = async (quote_id) => {
    if (quote_id !== 0 && quote_id !== undefined) {
      try {
        const data = await GetQuoteById(quote_id);
        if (data && data.quote) {
          setTaglist(data.quote.Tags);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    // If quote has tags, set them
    if (quote && quote.Tags) {
      setTaglist(quote.Tags);
    }
    // Also fetch from the quoteId param
    GetQuoteTaglist(params.quoteId);
  }, [quote, productobj, params.quoteId]);

  // Initialize itemObj if itemId === 0 (adding new)
  useEffect(() => {
    if (itemId === 0) {
      setItemObj({
        ...itemObj,
        Name: productobj.Name,
        CateType: productobj.CateType,
        Product_Id: productobj.Id,
        UnitPrice: productobj.UnitPrice,
        Unit: productobj.Unit,
        Quote_Id: params.quoteId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, productobj]);

  // If editing, fetch the existing item
  useEffect(() => {
    if (productobj !== undefined && itemId !== 0) {
      setManagerNotes(productobj.Notes);
      GetItem(itemId);
    }
  }, [productobj, itemId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset the calculator fields
  const ResetCalculator = () => {
    setCubicFeetTotal(0);
    setSquareFeet(0);
    setDepth(0);
  };

  // =================
  // Save / Update Logic
  // =================

  const AddNewItemToQuote = async (newItem) => {
    onClose(true);
    try {
      const data = await AddQuoteItem(newItem);
      if (data && data.item) {
        RefreshItemList(data.item);
        if (siteMapper === true) {
          ResetCalculator();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateItemOnQuote = async (existingItem) => {
    try {
      const data = await UpdateQuoteItem(existingItem);
      if (data && data.item) {
        RefreshUpdatedItem(data.item);
        ResetCalculator();
        onClose(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const SaveItem = async (objToSave) => {
    setIsLoading(true); // Start loading
    if (objToSave) {
      // For removals, compute volume
      if (objToSave.CateType === CategoryTypes.Removal) {
        objToSave.Quant = removalArea * (removalDepth / 12);
      }

      // If new item
      if (!objToSave.Id) {
        // For Material on siteMapper, auto-fill from squareFeetSM if user hasn't typed yet
        if (
          siteMapper === true &&
          objToSave.CateType === CategoryTypes.Material        ) {
          setInputValue(squareFeetSM.toFixed(2));
          objToSave.Quant = squareFeetSM.toFixed(2);
        }
        await AddNewItemToQuote(objToSave);
      } else {
        // Otherwise update
        await UpdateItemOnQuote(objToSave);
      }
    }
    setInputValue(0);
    setIsLoading(false);
    // setIsUserInput(false);
  };

  // ============
  // Render Logic
  // ============

  if (!open) return null;

  // -------------------------
  // 1) RENDER: ADD NEW ITEM
  // -------------------------
  if (itemId === 0) {
    return (
      <>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
          onClick={() => {
            setIsLoading(false);
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <FixedOverlay1000vh> */}
          <CalculatorWindow
            isVisible={calculatorsVisible}
            onClose={() => {
              setCalculatorsVisible(false);
            }}
            squareFeetProp={squareFeetSM}
          />
          {/* Main Form */}
          <StickyCont400x400px height={siteMapper === true ? "95vh" : ""}>
            <Div300pxMargin30px>
              {/* Show siteMapper info if relevant */}
              {siteMapper && (
                <FlexDivSpaceBetween>
                  <TinyTextItalics>
                    {squareFeetSM?.toFixed(2)} sqft. (area)
                  </TinyTextItalics>
                  <TinyTextItalics>
                    {perimeterSM?.toFixed(2)} ft. (perimeter)
                  </TinyTextItalics>
                <TinyTextItalics>
                    {iconCount} (icons)
                </TinyTextItalics>
                </FlexDivSpaceBetween>
              )}

              {/* Icon Count */}
         
              {/* Name + Calculator icon */}
              <FlexDiv>
                <LeagueSpartanHeaderSmall>Name</LeagueSpartanHeaderSmall>
                <Icon30px20pxPointer
                  squareFeet={squareFeetSM}
                  src="/calculator_icon.svg"
                  onClick={() => {
                    setCalculatorsVisible(true);
                  }}
                />
              </FlexDiv>
              <TextInput72Percent
                defaultValue={productobj.Name}
                onChange={(e) => {
                  setItemObj({ ...itemObj, Name: e.target.value });
                }}
              />
                  <br></br>
                  <br></br>


              {/* Manager notes in red, if any */}
              {managerNotes && (
                <RegularTextBoldRed>{managerNotes}</RegularTextBoldRed>
              )}

              {/* MATERIAL / OPEN / ADDON => Show quantity field */}
              {(productobj.CateType === CategoryTypes.Material ||
                productobj.CateType === CategoryTypes.Open ||
                productobj.CateType === CategoryTypes.Addon) && (
                <MaterialFields
                  productobj={productobj}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  itemObj={itemObj}
                  iconCount={iconCount}
                  siteMapper={siteMapper}
                  squareFeetSM={squareFeetSM}
                  // setIsUserInput={setIsUserInput}
                />
              )}

              {/* REMOVAL => Show area + depth */}
              {productobj.CateType === CategoryTypes.Removal && (
                <RemovalFields
                  removalArea={removalArea}
                  setRemovalArea={setRemovalArea}
                  removalDepth={removalDepth}
                  setRemovalDepth={setRemovalDepth}
                  siteMapper={siteMapper}
                  squareFeetSM={squareFeetSM}
                />
              )}

              {/* LABOR => Show hours */}
              {productobj.CateType === CategoryTypes.Labor && (
                <LaborFields itemObj={itemObj} />
              )}

              {/* OPEN => Show unit + price */}
              {productobj.CateType === CategoryTypes.Open && (
                <OpenFields productobj={productobj} itemObj={itemObj} />
              )}

              {/* Tag for Material / Open / Removal */}
              {(productobj.CateType === CategoryTypes.Material ||
                productobj.CateType === CategoryTypes.Open ||
                productobj.CateType === CategoryTypes.Removal) && (
                <TagField
                  productobj={productobj}
                  itemObj={itemObj}
                  taglist={taglist}
                  setAddTagWindowVisible={setAddTagWindowVisible}
                  setTagObj={setTagObj}
                />
              )}

              {/* Notes */}
              <LeagueSpartanHeaderSmall>Notes</LeagueSpartanHeaderSmall>
              <TextArea72Percent
                onChange={(e) => {
                  setItemObj({ ...itemObj, Notes: e.target.value });
                }}
              />

              <br />
              <br />
              {/* Buttons */}
              <FlexDiv>
              <IconButton onClick={() => {
                  if (siteMapper === true) {
                    onCloseNoAdd();
                  } else {
                    onClose(false);
                  }
                  ResetCalculator();
                }} width='50%' bgcolor={ColorPalette.PrimaryRed}>Cancel</IconButton>
              <IconButton onClick={() => SaveItem(itemObj)} width='50%' src='/pluscirclewhite_icon.svg'>Add Item</IconButton>
              </FlexDiv>
            </Div300pxMargin30px>
          </StickyCont400x400px>

          {/* Tag Window for adding new tags */}
          <TagWindow
            tagObj={tagObj}
            quoteid={params.quoteId}
            open={addTagWindowVisible}
            RefreshTaglist={() => {
              GetQuoteTaglist(params.quoteId);
            }}
            onClose={() => {
              setAddTagWindowVisible(false);
            }}
          ></TagWindow>
        {/* </FixedOverlay1000vh> */}
      </>
    );
  }

  // -------------------------
  // 2) RENDER: EDIT EXISTING ITEM
  // -------------------------
  else if (itemObj.Id === itemId) {
    return (
      <>
        {/* <FixedOverlay1000vh> */}
          <StickyCont400x400px>
            <Div300pxMargin30px>
              <LeagueSpartanHeaderSmall>Name</LeagueSpartanHeaderSmall>
              <TextInput72Percent
                defaultValue={itemObj.Name}
                key={itemObj.Name}
                onChange={(e) => {
                  setItemObj({ ...itemObj, Name: e.target.value });
                }}
              />

              {(itemObj.CateType === CategoryTypes.Material ||
                itemObj.CateType === CategoryTypes.Open ||
                itemObj.CateType === CategoryTypes.Addon) && (
                <>
                  <LeagueSpartanHeaderSmall>
                    Quantity ({itemObj.Unit}) of Product
                  </LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    key={itemObj.Quant}
                    defaultValue={itemObj.Quant}
                    onChange={(e) => {
                      setItemObj({ ...itemObj, Quant: e.target.value });
                    }}
                  />
                </>
              )}

              {itemObj.CateType === CategoryTypes.Removal && (
                <>
                  <LeagueSpartanHeaderSmall>Area (Square Feet)*</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    key={itemObj.Quant} // if you store the area in .Quant or separate state
                    onChange={(e) => {
                      setRemovalArea(e.target.value);
                    }}
                  />
                  <LeagueSpartanHeaderSmall>Depth (Inches)*</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    onChange={(e) => {
                      setRemovalDepth(e.target.value);
                    }}
                  />
                </>
              )}

              {itemObj.CateType === CategoryTypes.Labor && (
                <>
                  <LeagueSpartanHeaderSmall>Hours</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    key={itemObj.Quant}
                    defaultValue={itemObj.Quant}
                    onChange={(e) => {
                      setItemObj({ ...itemObj, Quant: e.target.value });
                    }}
                  />
                </>
              )}

              {itemObj.CateType === CategoryTypes.Open && (
                <>
                  <LeagueSpartanHeaderSmall>Unit</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    key={itemObj.Unit}
                    defaultValue={itemObj.Unit}
                    onChange={(e) => {
                      setItemObj({ ...itemObj, Unit: e.target.value });
                    }}
                  />
                  <LeagueSpartanHeaderSmall>Product Price</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    key={itemObj.UnitPrice}
                    defaultValue={itemObj.UnitPrice}
                    onChange={(e) => {
                      setItemObj({ ...itemObj, UnitPrice: e.target.value });
                    }}
                  />
                </>
              )}

              {(itemObj.CateType === CategoryTypes.Material ||
                itemObj.CateType === CategoryTypes.Open) && (
                <>
                  <LeagueSpartanHeaderSmall>Tag</LeagueSpartanHeaderSmall>
                  <TextSelect72Percent
                    defaultValue={itemObj.Tag_Id}
                    onChange={(e) => {
                      setItemObj({ ...itemObj, Tag_Id: e.target.value });
                    }}
                  >
                    <DatalistOption value={0}>General Materials</DatalistOption>
                    {taglist.map((o, i) => (
                      <DatalistOption value={o.Id} key={i}>
                        {o.TagName}
                      </DatalistOption>
                    ))}
                  </TextSelect72Percent>
                </>
              )}

              <LeagueSpartanHeaderSmall>Notes</LeagueSpartanHeaderSmall>
              <TextArea72Percent
                key={itemObj.Notes}
                defaultValue={itemObj.Notes}
                onChange={(e) => {
                  setItemObj({ ...itemObj, Notes: e.target.value });
                }}
              />

              <Cont100PercentWidth>
                <br />
                <RegularResponsiveButton onClick={() => SaveItem(itemObj)}>
                  Update
                </RegularResponsiveButton>
              </Cont100PercentWidth>

              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose(false);
                  ResetCalculator();
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </Div300pxMargin30px>
          </StickyCont400x400px>

          {/* In-page Calculator if Material */}
          {itemObj.CateType === CategoryTypes.Material && (
            <div>
              <AbsoluteCalculatorCont>
                <Div300pxMargin30px>
                  <LeagueSpartanHeaderSmall>
                    Square Feet to Cubic Feet
                  </LeagueSpartanHeaderSmall>
                  <StyledHRGreyBreak />
                  <LeagueSpartanHeaderSmall>Square Feet</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    onChange={(e) => setSquareFeet(+e.target.value || 0)}
                  />
                  <LeagueSpartanHeaderSmall>Depth (Inches)</LeagueSpartanHeaderSmall>
                  <TextInput72Percent
                    onChange={(e) => setDepth(+e.target.value || 0)}
                  />
                  <Cont100PercentWidth>
                    <LeagueSpartanHeaderSmall>
                      Cubic Feet: {cubicFeetTotal.toFixed(2)}
                    </LeagueSpartanHeaderSmall>
                  </Cont100PercentWidth>
                </Div300pxMargin30px>
              </AbsoluteCalculatorCont>
            </div>
          )}
        {/* </FixedOverlay1000vh> */}
      </>
    );
  }

  // If we get here, we’re loading or something is off
  return (
    <div>
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

QuoteItemWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  RefreshItemList: () => {},
  RefreshUpdatedItem: () => {},
  defaultName: "",
  unitForProduct: "",
  GetQuoteInfoById: () => {},
  onCloseNoAdd: () => {},
};

export default QuoteItemWindow;
