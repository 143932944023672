import styled from "styled-components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const nullProp = null;

export const EditFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;

  /* Switch to a single column on narrower screens */
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90vw;
  }
`;

/** Each column inside the edit form container */
export const EditFormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

/** A row for a single field: label + input or label + read-only text */
export const EditFieldRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  /* You can add margin-bottom or other styling here if desired. */
`;

/** 
 * Label for each field.
 * You can rename to something else if you prefer 
 * (ex: EditFieldLabel).
 */
export const EditFieldLabel = styled.label`
  min-width: 120px;
  font-weight: 500;
  color: #000;

  /* If you want them on separate lines for narrower screens:
  @media(max-width: 500px) {
    display: block;
    margin-bottom: 6px;
  } 
  */
`;

/** Text input field (replace if you have your own reusable input component). */
export const EditTextInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f6f6f6;
  margin-right: 25px;
  &:focus {
    outline: none;
    border-color: #007aff;
    background-color: #fff;
  }
`;

/** Select (or <datalist>) for city or region. */
export const EditSelect = styled.select`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f6f6f6;
  margin-right: 25px;
  &:focus {
    outline: none;
    border-color: #007aff;
    background-color: #fff;
  }
`;

/** 
 * A row for the Save / Cancel buttons (only shown when editing).
 * You can rename it to EditButtonsRow or something else if you prefer.
 */
export const EditActionButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 24px;
  justify-content: flex-end; 
  width: 95%;
`;

/* 
  If you have your own reusable button components, 
  you can skip these or rename them.
 */
export const EditButton = styled.button`
  padding: 10px 16px;
  background-color: #007aff;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #005bb5;
  }
  &:active {
    background-color: #003f82;
  }
`;

export const RegularResponsiveButton = styled.div`
  min-width: ${(props) => (props.minwidth ? props.minwidth : "100px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "30px")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "12px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 2px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "2.5")};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  pointer-events: ${(props) => (props.pointerevent ? props.pointerevent : "")};
  border-radius: 10px;
  transition: 0.3s;
  padding: 4px 10px;
  display: ${(props) => (props.display ? props.display : "block")};

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);

  &:hover {
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;


export const FixedIconToTop = styled.img`
  position: fixed;
  right: 0.5%;
  bottom: 0.25%;
  width: 50px;
  height: 50px;
  padding: 5px;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    opacity: 70%;
  }
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const BackTopTopArrow = styled.img`
  position: fixed;
  right: 2.5%;
  bottom: 2.5%;
  width: 50px;
  height: 50px;
  padding: 5px;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 999;
  &:hover {
    opacity: 70%;
  }
  @media (max-width: 1006px) {
    // display: none;
  }
`;

export const Select16px = styled.select`
  font-family: Avenir;
  font-size: min(0.75vw, 14px);
  border: 1px solid #d3d3d3;
  height: 40%;
  width: 12vw;
  margin-left: 5px;
  margin-right: 5px;
`;

export const SelectionInputRegular = styled.select`
  width: 200px;
  font-family: Avenir;
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
`;

export const Option16px = styled.option`
  font-family: Avenir;
  font-size: min(0.75vw, 14px);
`;

export default nullProp;
